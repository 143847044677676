// SalaryTransferList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as salaryService from "../../services/salaryService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { IWorkTimeEdit } from "../../models/workTime/workTimeEdit";
import { WorkTimeDialog } from "./workTimeDialog";
import { ISalaryListItem, SalaryListItem } from "../../models/salary/salaryListItem";
import { ISalaryWorkTimePeriod } from "../../models/salary/salaryWorkTimePeriod";
import { IEmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";

// SalaryTransferListLineHeader
export interface ISalaryTransferListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class SalaryTransferListLineHeader extends React.Component<ISalaryTransferListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.Date}
                    column="date"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Employee}
                    column="employeeName"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Comment}
                    column="comment"
                    classes="col-7"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Transferred}
                    column="transferDate"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// SalaryTransferListLine
export interface ISalaryTransferListLineProp {
    item: ISalaryListItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SalaryTransferListLine extends React.Component<ISalaryTransferListLineProp, {}> {
    handleFirsrColumnClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-1" onClick={this.handleFirsrColumnClick}>{Base.utcTimeToDateStr(item.date)}</div>
                <div className="col-3">{item.employeeName}</div>
                <div className="col-7">{item.comment}</div>
                <div className="col-1">{Base.utcTimeToDateStr(item.transferDate)}</div>
            </div>
        );
    }
}

// SalaryTransferList
export interface ISalaryTransferListProp {
    employeeGroup: IEmployeeGroupItem;
    employees: IEmployeeItem[];
    salaryPeriod: ISalaryWorkTimePeriod;
    onChangeSalaryPeriod: (salaryPeriodId: string) => void;
}

interface ISalaryTransferListState {
    isLoading: boolean;
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: ISalaryListItem[];
    selectedId: string;
    checkedIds: string[];
    // Edit
    workTimeEdit: IWorkTimeEdit;
    showWorkTimeDialog: boolean;
}

export class SalaryTransferList extends React.Component<ISalaryTransferListProp, ISalaryTransferListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props: ISalaryTransferListProp) {
        super(props);
        this.state = {
            isLoading: false,
            pageSize: infiniteListPageSize,
            page: 1,
            hasMore: false,
            filter: "",
            sortColumn: "date",
            sortOrderIsAsc: true,
            items: [],
            selectedId: null,
            checkedIds: [],
            workTimeEdit: null,
            showWorkTimeDialog: false
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        this.setState({
            isLoading: true
        });
        store.customStore.dispatch(StoreActions.fetchStart());
        salaryService.getSalaryListItems(this.props.salaryPeriod.id, !refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc, this.props.employees.map(i => i.id))
            .then(salaryItems => {
                const listItems = Base.getListItems<SalaryListItem>(obj.state.items, obj.state.selectedId, obj.state.checkedIds, salaryItems.items, resetItems, refreshList);
                obj.setState({
                    page: !refreshList ? salaryItems.page : page,
                    hasMore: salaryItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    items: listItems.items,
                    selectedId: listItems.selectedId,
                    checkedIds: listItems.checkedIds,
                });
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                this.setState({
                    isLoading: false
                });
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = () => {
        const state = this.state;
        this.searchItems(state.pageSize, state.page, state.filter, state.sortColumn, state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: SalaryTransferList) => {
        if (obj.state.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            const state = obj.state;
            obj.searchItems(state.pageSize, state.page + 1, state.filter, state.sortColumn, state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        const state = this.state;
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(state.pageSize, state.page, state.filter, state.sortColumn, state.sortOrderIsAsc, false, false);
    }

    componentDidUpdate(prevProps: ISalaryTransferListProp, prevState: ISalaryTransferListState): void {
        const props = this.props;
        const state = this.state;
        const prevSalaryPeriodId = prevProps.salaryPeriod ? prevProps.salaryPeriod.id : "";
        const salaryPeriodId = props.salaryPeriod ? props.salaryPeriod.id : "";
        const prevEmployeeRowIds = prevProps.employees.map(i => i.rowId);
        const employeeRowIds = props.employees.map(i => i.rowId);
        const prevEmployeeIds = prevProps.employees.map(i => i.id);
        const employeeIds = props.employees.map(i => i.id);
        const salaryParametersChanged = !(prevSalaryPeriodId === salaryPeriodId &&
            prevEmployeeIds.join("#") === employeeIds.join("#") &&
            prevEmployeeRowIds.join("#") === employeeRowIds.join("#"));
        if (salaryParametersChanged) {
            this.searchItems(state.pageSize, state.page, state.filter, state.sortColumn, state.sortOrderIsAsc, true, false);
        };
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        const state = this.state;
        this.searchItems(state.pageSize, 1, filter, state.sortColumn, state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const state = this.state;
        const oldSortColumn = state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !state.sortOrderIsAsc : true;
        this.searchItems(state.pageSize, 1, state.filter, sortColumn, sortOrderIsAsc, true, false);
    };

    handlePrevious = () => {
        const props = this.props;
        props.onChangeSalaryPeriod(props.salaryPeriod.previousId);
    };

    handleNext = () => {
        const props = this.props;
        props.onChangeSalaryPeriod(props.salaryPeriod.nextId);
    };

    getEditItem = (id: string) => {
        //const obj = this;
        //workTimeService.getWorkTimeEdit(id).then(editItem => {
        //    obj.setState({
        //        showWorkTimeDialog: true,
        //        workTimeEdit: editItem
        //    });
        //});
    };

    handleAdd = () => {
        //this.getEditItem(Base.emptyGuid);
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleEditDialogOk = () => {
        this.setState({
            showWorkTimeDialog: false
        });
        this.refreshList();
    };

    handleEditDialogCancel = () => {
        this.setState({
            showWorkTimeDialog: false
        });
    };

    handleRemove = () => {
        //const obj = this;
        //const selectedId = this.state.selectedId;
        //if (!selectedId) return;
        //const item = this.state.items.find(i => i.id === selectedId);
        //if (Base.isNullOrUndefined(item)) return;
        //store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.WorTimeRemoveConfirmation, item.number), () => {
        //    store.customStore.dispatch(StoreActions.clearConfirmation());
        //    // Call server
        //    store.customStore.dispatch(StoreActions.fetchStart());
        //    customerService.removeCustomer(selectedId)
        //        .then(success => {
        //            store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
        //            obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
        //        })
        //        .catch(error => {
        //            store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        //            return null;
        //        })
        //        .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
        //}, () => {
        //    store.customStore.dispatch(StoreActions.clearConfirmation());
        //}));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = state.items;
        return (
            <div>
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.SalaryDatas + " " + Base.utcTimeToDateStr(props.salaryPeriod.startDate) + "-" + Base.utcTimeToDateStr(props.salaryPeriod.endDate - 1) + (props.employeeGroup ? ", " + props.employeeGroup.name : "")}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Previous}
                            enabled={true}
                            classes={"round left previous"}
                            onClick={this.handlePrevious}
                        />
                        <ToolButton
                            title={Translations.Next}
                            enabled={true}
                            classes={"round left next"}
                            onClick={this.handleNext}
                        />
                        {/*<ToolButton*/}
                        {/*    title={Translations.Add}*/}
                        {/*    enabled={true}*/}
                        {/*    classes={"round left add"}*/}
                        {/*    onClick={this.handleAdd}*/}
                        {/*/>*/}
                        {/*<ToolButton*/}
                        {/*    title={Translations.Edit}*/}
                        {/*    enabled={!!state.selectedId}*/}
                        {/*    classes={"round left edit"}*/}
                        {/*    onClick={this.handleEdit}*/}
                        {/*/>*/}
                        {/*<ToolButton*/}
                        {/*    title={Translations.Remove}*/}
                        {/*    enabled={!!state.selectedId}*/}
                        {/*    classes={"round left remove"}*/}
                        {/*    onClick={this.handleRemove}*/}
                        {/*/>*/}
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <SalaryTransferListLineHeader
                        sortColumn={state.sortColumn}
                        sortOrderIsAsc={state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer main workTimesContainer">
                    <div className="list workTimes striped" ref={(elem) => { this.containerDiv = elem; }}>
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <SalaryTransferListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {state.showWorkTimeDialog &&
                    <WorkTimeDialog
                        editItem={state.workTimeEdit}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}
