import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, configureStore, combineReducers, Reducer, PreloadedState } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { alert, fetchCount, acknowledgementMain, confirmation, initialState, install, owner, ownerRoutePoints, shoppingCart, user, workMain, reportResponse } from "../models/store/storeReducers";
import { Session } from "../models/session/session";
import * as StoreActions from "../models/store/storeActions";

import { reportingReducer, reportingSlice } from "../store/reportingSlice";
import { storageReducer, storageSlice } from "../store/storageSlice";
import { timelinePlannerReducer, timelinePlannerSlice } from "../store/timelinePlannerSlice";
import { transportOrdersReducer, transportOrdersSlice } from "../store/transport/transportOrdersSlice";
import { parcelReducer, parcelSlice } from "../store/parcelSlice";
import { transportOrderInvoiceReducer, transportOrderInvoiceSlice } from "../store/transportOrderInvoiceSlice";
import { transportPlansReducer, transportPlansSlice } from "../store/transport/transportPlansSlice";
import { transportPlanTemplatesReducer, transportPlanTemplatesSlice } from "../store/transport/transportPlanTemplatesSlice";
import { transportVehiclesReducer, TransportVehiclesSlice } from "../store/transport/transportVehiclesSlice";
import { workTimeBetaReducer, workTimeBetaSlice } from "../store/workTimeBetaSlice";
import { workShiftTimeSlotReducer, workShiftTimeSlotSlice } from "../store/workShiftTimeSlotSlice";
import { employeesReducer, employeesSlice } from "../store/employeeSlice";
import { workHoursReducer, workHoursSlice } from "../store/workHoursSlice";
import { workShiftReducer, workShiftSlice } from "../store/workShiftSlice.";
import { calendarReducer, calendarSlice } from "../store/calendarSlice";
import { appReducer, appSlice } from "../store/appSlice";

const combinedReducer = combineReducers({
    fetchCount,
    install,
    alert,
    confirmation,
    owner,
    user,
    acknowledgementMain,
    workMain,
    ownerRoutePoints,
    shoppingCart,
    reportResponse,
    [employeesSlice.name]: employeesReducer,
    [timelinePlannerSlice.name]: timelinePlannerReducer,
    [storageSlice.name]: storageReducer,
    [reportingSlice.name]: reportingReducer,
    [workTimeBetaSlice.name]: workTimeBetaReducer,
    [TransportVehiclesSlice.name]: transportVehiclesReducer,
    [transportPlansSlice.name]: transportPlansReducer,
    [transportPlanTemplatesSlice.name]: transportPlanTemplatesReducer,
    [transportOrdersSlice.name]: transportOrdersReducer,
    [parcelSlice.name]: parcelReducer,
    [transportOrderInvoiceSlice.name]: transportOrderInvoiceReducer,
    [workShiftTimeSlotSlice.name]: workShiftTimeSlotReducer,
    [workHoursSlice.name]: workHoursReducer,
    [workShiftSlice.name]: workShiftReducer,
    [calendarSlice.name]: calendarReducer,
    [appSlice.name]: appReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    return combinedReducer(state, action);
};

export const customStore = configureStore({
    reducer: rootReducer,
    middleware: [
        thunk,
    ],
    preloadedState: initialState,
});

// Test helper fn to setup store using actual reducers. Provide preloadedState to alter initial state
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    });
};

export type AppDispatch = typeof customStore.dispatch;
export type Store = ReturnType<typeof customStore.getState>;

// TS wrappers for select and dispatch. Use to avoid boilerplate.
export const useAppDispatch = () : AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Initialize authentication
void Session.getAccessToken().then(i => {
    if (!i) return;
    customStore.dispatch(StoreActions.loginSuccess());
}).catch(console.error);
