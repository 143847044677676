import React, { useCallback, useMemo } from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { useParams } from "react-router-dom";
import { MuiDataGrid } from "../framework/muiDataGrid";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { removeEmployeeDayBookings } from "../../services/employeeService";
import {
    DayBookingItemBeta,
} from "../../models/dayBooking/dayBookingItemBeta";
import { DayBookingTypeItemBeta } from "../../models/dayBookingType/dayBookingTypeItemBeta";
import * as baseService from "../../services/baseService";
import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import MuiMenu from "../framework/muiMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { SaveData } from "../../framework/saveData";
import { StrMeasureUnit } from "../../models/common/enums";

interface IWorkTimeDetailsDayBookingListProps {
    setEditDayBookingItem: React.Dispatch<React.SetStateAction<DayBookingItemBeta>>;
    reloadList: () => void;
    dayBookingItems: DayBookingItemBeta[];
    dayBookingTypes: DayBookingTypeItemBeta[];
}

export function WorkTimeDetailsDayBookingList({
    setEditDayBookingItem,
    reloadList,
    dayBookingItems,
    dayBookingTypes,
}: IWorkTimeDetailsDayBookingListProps) {
    const {
        employeeId,
    } = useParams<IWorkTimeDetailsPage>();

    const handleDelete = useCallback(
        (id: string) => {
            if (!id || !employeeId) return;
            store.customStore.dispatch(
                storeActions.setConfirmation(
                    ConfirmationDialogType.Warning,
                    Translations.Warning,
                    Translations.DayBookingRemoveConfirmation,
                    () => {
                        store.customStore.dispatch(storeActions.clearConfirmation());
                        // Call server
                        store.customStore.dispatch(storeActions.fetchStart());
                        const dayBookingIds = [id];
                        const saveData = new SaveData();
                        saveData.append("employeeId", employeeId);
                        saveData.append("dayBookingIds", JSON.stringify(dayBookingIds));
                        removeEmployeeDayBookings(saveData.formData)
                            .then((success) => {
                                store.customStore.dispatch(
                                    storeActions.showSuccessMessage(success.message)
                                );
                                reloadList();
                            })
                            .catch((error) => {
                                store.customStore.dispatch(
                                    storeActions.showErrorMessage(
                                        baseService.getErrorMessageFromError(error)
                                    )
                                );
                                return null;
                            })
                            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
                    },
                    () => {
                        store.customStore.dispatch(storeActions.clearConfirmation());
                    }
                )
            );
        }, [employeeId, reloadList]
    );

    const handleEdit = useCallback(
        (id: string) => {
            setEditDayBookingItem(dayBookingItems.find((i) => i.id === id));
        },
        [dayBookingItems, setEditDayBookingItem]
    );
    interface IDayColumnValueGetter {
        day: string;
        duration: string;
        label: string;
    }
    const cols: GridColDef[] = useMemo(
        () => [
            {
                field: "day",
                headerName: Translations.Time,
                flex: 1,
                valueGetter: (params: GridValueGetterParams<DayBookingItemBeta>) => {
                    return {
                        day: params.row.day,
                        duration: Math.floor(params.row.amount),
                        label: `${Base.dayjsToDateStr(params.row.day)}-${Base.dayjsToDateStr(new DayBookingItemBeta(params.row).getEndDay())}`,
                    };
                },
                valueFormatter: (params: GridValueFormatterParams<IDayColumnValueGetter>) =>
                    params.value?.label,
                sortComparator: (a, b) => Base.stringCompare(a.day, b.day) || b.dayUsageType - a.dayUsageType || Base.numberCompare(a.duration, b.duration),
            },
            {
                field: "amount",
                valueGetter: (params: GridValueGetterParams<DayBookingItemBeta>) => {
                    const dayBookingType = dayBookingTypes && dayBookingTypes.find(type => type?.id === params.row?.dayBookingTypeId);
                    const duration = dayBookingType && params.row.getDurationStrBasedOnUnit();
                    const unit = dayBookingType?.salaryRowTypeMeasureUnit || StrMeasureUnit.Day;
                    if(duration && unit) return `${duration} (${unit})`;
                    return `${duration} `;
                },
                valueFormatter: (params: GridValueFormatterParams<string>) => {
                    return params.value;
                },
                headerName: `${Translations.Duration}`,
                flex: 1,
            },
            {
                field: "dayBookingTypeId",
                headerName: Translations.DayBookingType,
                flex: 2,
                valueGetter: (params: GridValueGetterParams) =>
                    dayBookingTypes?.find(
                        (i) => i.id === params.row.dayBookingTypeId
                    ).name,
            },
            {
                field: "comment",
                headerName: Translations.Description,
                flex: 2,
            },
            {
                field: "id",
                headerName: "",
                width: 75,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params: GridRenderCellParams) => (
                    <MuiMenu
                        label={<MoreHorizIcon color="primary"/>}
                        items={[
                            {
                                label: Translations.Edit,
                                icon: <EditIcon color="primary"/>,
                                onClick: () => handleEdit(params.value),
                            },
                            {
                                label: Translations.Remove,
                                icon: <DeleteIcon color="error"/>,
                                onClick: () => handleDelete(params.value),
                            },
                        ]}
                    />
                ),
            },
        ],
        [dayBookingTypes, handleEdit, handleDelete]
    );

    if (!(dayBookingItems?.length > 0)) {
        return null;
    }

    return (
        <MuiDataGrid
            title={Translations.DayBookings}
            disableRowSelectionOnClick
            autoHeight
            rows={dayBookingItems}
            columns={cols}
            hideFooter
            disableColumnMenu
            disableVirtualization
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: "startDate",
                            sort: "asc",
                        },
                    ],
                },
            }}
        />
    );
}
