import * as React from "react";
import { WorkNoBox } from "../work/workNoBox";

// WorkTimeDialogWorkOrder
export interface IWorkTimeDialogWorkOrderProp {
    id: string;
    state: number;
    number: number;
    parentNumber: number;
    name: string;
    description: string;
}

export class WorkTimeDialogWorkOrder extends React.Component<IWorkTimeDialogWorkOrderProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className={"workTimeType workOrder"}>
                <div className="firstLine">
                    <div className="iconContainer"><span className="icon img work" /></div>
                    <WorkNoBox
                        id={props.id}
                        state={props.state}
                        number={props.number}
                        parentNumber={props.parentNumber ? props.parentNumber : null}
                        onClick={null}
                    />
                    <div className="name">{props.name}</div>
                </div>
                <div className="description">{props.description}</div>
            </div>);
    }
}