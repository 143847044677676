// SelectWorkOrderDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as workOrderService from "../../services/workOrderService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { ListHeaderColumn, ListHeaderCheckBoxColumn } from "../framework/listHeaderColumn";
import { IWorkOrderListItem } from "../../models/work/workOrderListItem";
import { IIdTitle } from "../../models/common/idTitle";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { ToolTitle } from "../framework/toolTitle";
import { ListSearchFilter } from "../framework/listSearchFilter";

// SelectWorkOrderDialogListHeader
export interface ISelectWorkOrderDialogListHeaderProp {
    multiSelect: boolean;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class SelectWorkOrderDialogListHeader extends React.Component<ISelectWorkOrderDialogListHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        this.props.onColumnCheckboxChange(column, checked);
    };

    render() {
        const props = this.props;
        return (
            <div className="row title">
                {props.multiSelect &&
                    <ListHeaderCheckBoxColumn
                        key="number"
                        title={Translations.AbrNumber}
                        column="number"
                        classes="col-1"
                        sortColumn={props.sortColumn}
                        sortOrderIsAsc={props.sortOrderIsAsc}
                        onClick={this.handleColumnClick}
                        onCheckboxChange={this.handleColumnCheckboxChange}
                    />
                }
                {!props.multiSelect &&
                    <ListHeaderColumn
                        title={Translations.AbrNumber}
                        column="number"
                        classes="col-1"
                        sortColumn={props.sortColumn}
                        sortOrderIsAsc={props.sortOrderIsAsc}
                        onClick={this.handleColumnClick}
                    />
                }
                <ListHeaderColumn
                    title={Translations.State}
                    column="state"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.StartDate}
                    column="startTime"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.EndDate}
                    column="endTime"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-4"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customer"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Site}
                    column="siteName"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// SelectWorkOrderDialogListLine
export interface ISelectWorkOrderDialogListLineProp {
    checked: boolean;
    item: IWorkOrderListItem;
    multiSelect: boolean;
    selectedId: string;
    onClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class SelectWorkOrderDialogListLine extends React.Component<ISelectWorkOrderDialogListLineProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (" state" + item.state) + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} >
                <div className="col-1">
                    {props.multiSelect &&
                        <input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />
                    }
                    {item.number.toString(10) + (item.isTask() ? " (" + item.parentNumber.toString(10) + ")" : "")}
                </div>
                <div className="col-1">{item.stateStr}</div>
                <div className="col-1">{Base.utcTimeToDateStr(item.startTime)}</div>
                <div className="col-1">{Base.utcTimeToDateStr(item.endTime)}</div>
                <div className="col-4">{item.name}</div>
                <div className="col-2">{item.customerName}</div>
                <div className="col-2">{item.siteName}</div>
            </div>
        );
    }
}

// SelectWorkOrderDialog
export interface ISelectWorkOrderDialogProp {
    classes?: string;
    filter?: string;
    ignoreWorkOrderIds?: string[];
    categories?: number[];
    multiSelect?: boolean;
    title: string;
    workOrderTypeId?: string;
    startDate?: number;
    endDate?: number;
    onOk: (workOrders: IWorkOrderListItem[]) => void;
    onCancel: () => void;
}

export interface ISelectWorkOrderDialogState {
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IWorkOrderListItem[];
    workOrderStates: IIdTitle[];
    state: number;
    workOrderTypes: IIdTitle[];
    workOrderTypeId: string;
    selectedId: string;
    checkedIds: string[];
}

export class SelectWorkOrderDialog extends React.Component<ISelectWorkOrderDialogProp, ISelectWorkOrderDialogState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            pageSize: infiniteListPageSize,
            page: 1,
            hasMore: false,
            filter: props.filter ? props.filter : "",
            sortColumn: "number",
            sortOrderIsAsc: true,
            items: [],
            workOrderStates: [],
            state: -1,
            workOrderTypes: [],
            workOrderTypeId: props.workOrderTypeId ? props.workOrderTypeId : "",
            selectedId: null,
            checkedIds: []
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, state: number, workOrderTypeId: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null): Promise<void> => {
        const obj = this;
        const props = this.props;
        obj.isLoading = true;
        store.customStore.dispatch(StoreActions.fetchStart());
        return workOrderService.getWorkOrderListItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, [state], [], sortColumn, sortOrderIsAsc, props.categories, props.ignoreWorkOrderIds, [workOrderTypeId], props.startDate, props.endDate)
            .then(workOrderItems => {
                let items: IWorkOrderListItem[];
                if (!resetItems && !refreshList) {
                    const oldIds = {};
                    for (let j = 0; j < obj.state.items.length; j++) {
                        oldIds[obj.state.items[j].id] = true;
                    }
                    const oldItems = obj.state.items.slice(0);
                    const newItems = workOrderItems.items.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
                    items = [...oldItems, ...newItems];
                } else {
                    items = workOrderItems.items;
                }
                const selectedId = items.findIndex(i => i.id === obj.state.selectedId) > -1 ? obj.state.selectedId : "";
                const checkedIds = items.filter(i => obj.state.checkedIds.indexOf(i.id) > -1).map(i => i.id);
                obj.setState({
                    page: !refreshList ? workOrderItems.page : page,
                    hasMore: workOrderItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    items: items,
                    workOrderStates: workOrderItems.workOrderStates,
                    workOrderTypes: workOrderItems.workOrderTypes,
                    selectedId: selectedId,
                    checkedIds: checkedIds
                });
                if (successCallback) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.isLoading = false;
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = (): Promise<void> => {
        const state = this.state;
        return this.searchItems(state.pageSize, state.page, state.filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: SelectWorkOrderDialog) => {
        const state = obj.state;
        if (obj.isLoading || !state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(state.pageSize, state.page + 1, state.filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        const state = this.state;
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(state.pageSize, state.page, state.filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        const state = this.state;
        this.searchItems(state.pageSize, 1, filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const state = this.state;
        const oldSortColumn = state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !state.sortOrderIsAsc : true;
        this.searchItems(state.pageSize, 1, state.filter, state.state, state.workOrderTypeId, sortColumn, sortOrderIsAsc, true, false);
    };

    handleChange = (event) => {
        const state = this.state;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "state") {
            const newState = value.toInteger(true, -1);
            this.searchItems(state.pageSize, state.page, state.filter, newState, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, true, false);
            this.setState({ state: newState });
        } else if (name === "workOrderTypeId") {
            this.searchItems(state.pageSize, state.page, state.filter, state.state, value, state.sortColumn, state.sortOrderIsAsc, true, false);
            this.setState({ workOrderTypeId: value });
        }
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        const result = Base.getIdsOnColumnCheckboxChange(this.state.items, checked);
        this.setState({ selectedId: result.selectedId, checkedIds: result.checkedIds });
    };

    handleLineCheckboxChange = (id: string, checked: boolean) => {
        const result = Base.getIdsOnLineCheckboxChange(this.state.selectedId, this.state.checkedIds, id, checked);
        this.setState({ selectedId: result.selectedId, checkedIds: result.checkedIds });
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleOkClick = () => {
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.selectedId, state.checkedIds);
        if (selectedIds.length < 1) return;
        const workOrders = state.items.filter(i => selectedIds.indexOf(i.id) > -1);
        if (workOrders.length < 1) return;
        this.props.onOk(workOrders);
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "selectWorkOrder px1400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={props.title}
                    show={true}
                    body={<div>
                        <div className="row commandRow main">
                            <ToolTitle
                                title={Translations.WorkOrders}
                            />
                            <div className="col">
                                <select className="custom-select tool stateContainer" name="state" title={Translations.State} value={state.state} onChange={this.handleChange}>
                                    {state.workOrderStates.map((workOrderState) =>
                                        <option key={workOrderState.id} value={workOrderState.id}>{workOrderState.title}</option>
                                    )}
                                </select>
                                <select className="custom-select tool workOrderTypeContainer" name="workOrderTypeId" title={Translations.WorkOrderType} value={state.workOrderTypeId} onChange={this.handleChange}>
                                    {state.workOrderTypes.map((workOrderType) =>
                                        <option key={workOrderType.id} value={workOrderType.id}>{workOrderType.title}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-auto right">
                                <ListSearchFilter
                                    searchFilter={this.state.filter}
                                    onSearchClick={this.changeFilter}
                                />
                            </div>
                        </div>
                        <div>
                            <SelectWorkOrderDialogListHeader
                                multiSelect={props.multiSelect}
                                sortColumn={state.sortColumn}
                                sortOrderIsAsc={state.sortOrderIsAsc}
                                onColumnClick={this.changeSortColumn}
                                onColumnCheckboxChange={this.handleColumnCheckboxChange}
                            />
                        </div>
                        <div className="listContainer workOrdersContainer">
                            <div className="list workOrders striped" ref={(elem) => { this.containerDiv = elem; }}>
                                <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                                    {state.items.map((item) =>
                                        <SelectWorkOrderDialogListLine
                                            key={item.id}
                                            checked={state.checkedIds.indexOf(item.id) > -1}
                                            item={item}
                                            multiSelect={props.multiSelect}
                                            selectedId={state.selectedId}
                                            onClick={this.handleClick}
                                            onCheckboxChange={this.handleLineCheckboxChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: Base.getSelectedIds(state.selectedId, state.checkedIds).length > 0, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
