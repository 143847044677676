import { Base } from "../../framework/base";
import { AppUtils } from "../common/appUtils";
import { DayBookingUsageType, StrMeasureUnit } from "../common/enums";

export class DayBookingItemBeta {
    id: string;
    rowId: string;
    employeeId: string;
    dayBookingTypeId: string;
    startDate: number;
    endDate: number;
    day: string;
    amount: number;
    bookingType: number;
    dayUsageType: number;
    comment: string;
    dayHoursRatio: number;
    salaryRowTypeId: string;
    salaryRowTypeMeasureUnit: string;
    salaryRowTypeDecimals: number;


    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.rowId = obj && obj.rowId || "";
        this.employeeId = obj && obj.employeeId || "";
        this.dayBookingTypeId = obj && obj.dayBookingTypeId || "";
        this.startDate = obj && obj.startDate || 0;
        this.endDate = obj && obj.endDate || 0;
        this.day = obj && obj.day || "";
        this.amount = obj && obj.amount || 0;
        this.bookingType = obj && obj.bookingType || 0;
        this.dayUsageType = obj && obj.dayUsageType || 0;
        this.comment = obj && obj.comment || "";
        this.dayHoursRatio = obj && obj.dayHoursRatio || 8;
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || "";
        this.salaryRowTypeMeasureUnit = obj && obj.salaryRowTypeMeasureUnit || "";
        this.salaryRowTypeDecimals = obj && obj.salaryRowTypeDecimals || 0;
    }
    // Returns duration only if salary row measure units is in hours
    getDurationInHours(onlyOneDay = false): number {
        return AppUtils.isTimeUnit(this.salaryRowTypeMeasureUnit) ? this.getDurationBasedOnUnit(onlyOneDay) : 0;
    }

    getDurationBasedOnUnit(onlyOneDay = false): number {
        if (this.dayUsageType === DayBookingUsageType.FullDay) {
            if (AppUtils.isTimeUnit(this.salaryRowTypeMeasureUnit)) {
                return onlyOneDay ? this.dayHoursRatio : this.amount * this.dayHoursRatio;
            } else if (onlyOneDay) {
                return 1; // If not time unit, amount for full day is always 1
            }
        } else if (this.dayUsageType === DayBookingUsageType.NotFullDay && !AppUtils.isTimeUnit(this.salaryRowTypeMeasureUnit)) {
            if (this.salaryRowTypeMeasureUnit === StrMeasureUnit.Piece) return this.amount;
            return this.amount / this.dayHoursRatio;
        }

        return this.amount;
    }

    getDurationStrBasedOnUnit(onlyOneDay = false): string {
        if (this.dayUsageType === DayBookingUsageType.NotFullDay && AppUtils.isTimeUnit(this.salaryRowTypeMeasureUnit)) {
            return AppUtils.getDurationStrByDurationMinShort(
                Base.hoursToMinutes(this.amount)
            );
        }

        return this.getDurationBasedOnUnit(onlyOneDay).toString();
    }

    getEndDay(): string {
        if (this.dayUsageType === DayBookingUsageType.FullDay) {
            const added = Base.dateAddDays(this.day, Math.floor(this.amount) - 1);
            return added.format("YYYY-MM-DD");
        }

        return this.day;
    }
}
