import * as React from "react";

// WorkTimeDialogCostCenter
export interface IWorkTimeDialogCostCenterProp {
    name: string;
    description: string;
}

export class WorkTimeDialogCostCenter extends React.Component<IWorkTimeDialogCostCenterProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="workTimeType">
                <div className="firstLine">
                    <div className="iconContainer"><span className="icon img work" /></div>
                    <div className="name">{props.name}</div>
                </div>
                <div className="description">{props.description}</div>
            </div>);
    }
}