import { postApiCall, getApiCall, postFormApiCall } from "./tokenService";
import { WorkTimeListItems } from "../models/workTime/workTimeListItems";
import { WorkTimePageMain } from "../models/workTime/workTimePageMain";
import { WorkTimeEdit } from "../models/workTime/workTimeEdit";
import { SaveSuccess, ApiSuccess } from "./baseService";
import { WorkTimePageMainEmployeesAndGroups } from "../models/workTime/workTimePageMainEmployeesAndGroups";

export const getWorkTimePageMain = (): Promise<WorkTimePageMain> => {
    return getApiCall<WorkTimePageMain>("api/worktime/worktimepagemain", WorkTimePageMain);
};

export const getWorkTimePageMainEmployeesAndGroups = (): Promise<WorkTimePageMainEmployeesAndGroups> => {
    return getApiCall<WorkTimePageMainEmployeesAndGroups>("api/worktime/worktimepagemainemployeesandemployeegroups", WorkTimePageMainEmployeesAndGroups);
};

export const getWorkTimeListItems = (employeeIds: string[], salaryPeriodId: string): Promise<WorkTimeListItems> => {
    return postApiCall<WorkTimeListItems>("api/worktime/list", WorkTimeListItems, JSON.stringify({
        employeeIds: employeeIds,
        salaryPeriodId: salaryPeriodId
    }));
};

export const getWorkTimeEdit = (id: string): Promise<WorkTimeEdit> => {
    return getApiCall<WorkTimeEdit>("api/worktime/edit/?id=" + (id || ""), WorkTimeEdit);
};

export const saveWorkTimeEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/worktime/save?skipWorkTimeTypeId=true&categoryId=true", data, SaveSuccess);// looks like categoryId is not in use in backend
};

export const removeWorkTime = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/worktime/remove/?id=" + (id || ""), ApiSuccess);
};

export const lockWorkTimes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/worktime/lock", ApiSuccess, JSON.stringify({
        ids: ids
    }));
};

export const unlockWorkTimes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/worktime/unlock", ApiSuccess, JSON.stringify({
        ids: ids
    }));
};
