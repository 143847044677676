/* eslint-disable no-unused-vars */
export const enum WorkOrderState {
    Preliminary,
    Planned,
    InProgress,
    Done,
    Checked,
    Transferred,
    Undefined = 100
}

export const enum WorkOrderClass {
    Work,
    Project,
    ProjectTask,
    RecurringWork,
    RecurringTask
}

export const enum MeasureUnit
{
    Unknown, // 0
    Pcs,
    Kg,
    M,
    L,
    H,
    Km,
    Pkg,
    Btl,
    m2,
    Tn,
    m3
}

export const enum WorkOrderAcknowledgementTypeSendingType {
    Unknown,
    SendAlways,
    SendManually,
    DoNotSend
}

export const enum DayBookingUsageType {
    FullDay,
    NotFullDay
}

export enum WorkOrderCategory
{
    Unknown,
    Work,
    Project,
    Task
}

export const enum InvoiceState {
    Preliminary,
    Transferred
}

export const enum CalendarLineType {
    Employee,
    Vehicle,
    Project,
    RecurringProject,
    ProjectTask,
    RecurringProjectTask,
    Team,
    Unassigned,
    Unscheduled,
    Title
}

export const enum CalendarAccuracyType {
    DayTwoWeeks = "0",
    FourHours = "1",
    QuarterHour = "2"
}

export const enum ReportType {
    WorkOrder = "WorkOrder",
    Invoice = "Invoice",
    WorkTime = "WorkTime",
    Settings = "Settings",
}

export const enum ReportParameterType {
    Undefined = "",
    String = "String",
    Date = "Date",
    ValueList = "ValueList",
    HierarchialValueList = "HierarchialValueList",
    LookupValueList = "LookupValueList"
}

export const enum RecurrenceType {
    NoRecurrence,
    Daily,
    Weekly,
    Monthly,
    Yearly,
}

export const enum WorkShiftTimeSlotState {
    InProgress,
    Done,
    Locked
}

//WorkShiftTimeSlotType Category: WorkTime
export const enum WorkShiftTimeSlotTypeCategoryWorkTime {
    Work,
    Break,
    LunchBreak
}

export const enum WorkTimeTypeType {
    Break = 0,
    Driving = 1,
    Loading = 2,
    UnLoading = 3,
    Waiting = 4,
    Other = 5
}

export const enum WorkShiftTimeSlotTypeUsageType {
    WorkTime,
    WorkOrderRoutePoint
}

export const enum TimeFormat {
    DecimalFormat,
    TimeFormat
}

export const enum OptionDataType {
    String,
    Integer,
    Boolean,
    GuidArray
}

export const enum MapFilterMode {
    WorkOrderCalendar = "WorkOrderCalendar",
    WorkOrderFavorites = "WorkOrderFavorites"
}

export const enum WorkOrderTypeSiteRoutePointUsageType {
    Site,
    RoutePoint
}

export const enum WorkOrderTypeEmployeeUsageType {
    NotInUse,
    One,
    Multiple
}

export const enum WorkOrderTypeVehicleUsageType {
    NotInUse,
    One,
    Multiple
}

export const enum WorkOrderTypeLocationUsageType {
    NotInUse,
    InUse
}

export const enum WorkOrderTypeHoursInvoicingType {
    NotInUse,
    Realized,
    Planned
}

export const enum WorkOrderTypeKmsInvoicingType {
    NotInUse,
    Realized,
    Planned
}

export const enum SiteType {
    Point,
    Route,
    NoLocation,
    Storage
}

export const enum UserGroupPermission
{
    None,
    Edit,
    Admin
}

export const enum DocumentType {
    Attachment,
    AttachmentToInvoice,
}

export const enum ConfirmationDialogResult {
    Yes,
    No,
    Cancel
}

export const enum WorkTimeAccountTransactionType {
    Deposit = 0,
    Withdraw = 1
}

export const enum LocationPointType {
    General,
    WorkOrder
}

export class EnumHelper {
    static isEqual(i: number, e: WorkOrderState | MeasureUnit | WorkOrderAcknowledgementTypeSendingType | DayBookingUsageType | WorkOrderCategory | InvoiceState | CalendarLineType | RecurrenceType | WorkShiftTimeSlotState | WorkShiftTimeSlotTypeCategoryWorkTime | WorkShiftTimeSlotTypeUsageType
                                 | TimeFormat | WorkOrderTypeSiteRoutePointUsageType | WorkOrderTypeEmployeeUsageType | WorkOrderTypeVehicleUsageType | SiteType | UserGroupPermission | WorkTimeAccountTransactionType): boolean {
        return i > e - 0.5 && i < e + 0.5;
    }

    static isLessThan(i: number, e: WorkOrderState | MeasureUnit | WorkOrderAcknowledgementTypeSendingType | DayBookingUsageType | WorkOrderCategory | InvoiceState | CalendarLineType | RecurrenceType | WorkShiftTimeSlotState | WorkShiftTimeSlotTypeCategoryWorkTime | UserGroupPermission): boolean {
        return i < e - 0.5;
    }

    static isGreaterThan(i: number, e: WorkOrderState | MeasureUnit | WorkOrderAcknowledgementTypeSendingType | DayBookingUsageType | WorkOrderCategory | InvoiceState | CalendarLineType | RecurrenceType | WorkShiftTimeSlotState | WorkShiftTimeSlotTypeCategoryWorkTime | UserGroupPermission): boolean {
        return i > e + 0.5;
    }

    static isLessOrEqual(i: number, e: WorkOrderState | MeasureUnit | WorkOrderAcknowledgementTypeSendingType | DayBookingUsageType | WorkOrderCategory | InvoiceState | CalendarLineType | RecurrenceType | WorkShiftTimeSlotState | WorkShiftTimeSlotTypeCategoryWorkTime | UserGroupPermission): boolean {
        return EnumHelper.isLessThan(i, e) || EnumHelper.isEqual(i, e);
    }

    static isGreaterOrEqual(i: number, e: WorkOrderState | MeasureUnit | WorkOrderAcknowledgementTypeSendingType | DayBookingUsageType | WorkOrderCategory | InvoiceState | CalendarLineType | RecurrenceType | WorkShiftTimeSlotState | WorkShiftTimeSlotTypeCategoryWorkTime | UserGroupPermission): boolean {
        return EnumHelper.isGreaterThan(i, e) || EnumHelper.isEqual(i, e);
    }
}

export const enum DbOperationResult {
    Ok = 0,
    NotFound = 1,
    ConcurrencyError = 2,
    Error = 3
}

export const enum RoutePointTypeEnum {
    OtherType = 0,
    Begin = 1,
    End = 2,
    Fetch = 3,
    Delivery = 4
}

export const enum StrMeasureUnit {
    Hour = "h",
    HourFi = "t",
    Piece = "kpl",
    Day = "pv",
    DayFiAlt = "p",
    DayEn = "d",
}

export const enum FetchState {
    None = "none",
    Fetching = "fetching",
    Done = "done"
}

export const enum AlertSeverity {
    Error = "error",
    Warning = "warning",
    Info = "info",
    Success = "success",
}

/* eslint-enable no-unused-vars */
