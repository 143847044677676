import { Base } from "../models/../framework/base";
import { handleResponse, authorizationHeaderRefreshTokenValue, getJsonHeaders, handleFileResponse, getFormDataHeaders, handleFileBlobResponse, ApiSuccess } from "./baseService";
import { SessionState, ISessionState } from "../models/session/sessionState";
import { Session } from "../models/session/session";
import { Translations } from "../models/translations";
import { IEndlessList, EndlessList } from "../models/common/endlessList";
import * as StoreActions from "../models/store/storeActions";
import * as CustomStore from "../models/../framework/customStore";
import { isFileResponse } from "./baseService"
import { customHistory } from "../framework/customHistory";

export const login = (username: string, password: string): Promise<ISessionState> => {
    const requestOptions = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=utf-8",
            "Authorization": "Basic " + Base.b64EncodeUnicode(username) + ":" + Base.b64EncodeUnicode(password)
        }
    };
    return fetch(appConfig.rootUrl + "api/token", requestOptions)
        .then(handleResponse, handleError)
        .then(data => {
            if (!data || !data.accessToken) {
                return null;
            }
            const sessionState = new SessionState(data);
            appConfig.culture = sessionState.culture;
            Translations.setCulture(appConfig.culture);
            CustomStore.customStore.dispatch(StoreActions.setOwnerState(sessionState.ownerName, sessionState.ownerName2, sessionState.ownerParameters));
            CustomStore.customStore.dispatch(StoreActions.setUserState(sessionState.id, sessionState.isOwnerAdmin, sessionState.ssoIntegrations));
            return Session.setSessionState(sessionState).then(() => {
                return sessionState;
            });
            // console.log("login: NotificationManager.requestNotificationPermission()");
        });
};

const logoutCall = (): Promise<ApiSuccess> => {
    return getJsonHeaders(null).then(i => {
        const requestOptions = {
            headers: i
        };
        return fetch(appConfig.rootUrl + "api/token/logout", requestOptions)
            .then(handleResponse, handleError)
            .then(data => {
                return !data ? new ApiSuccess() : new ApiSuccess(data);
            });
    });
};

export const logout = () : Promise<void> => {
    return Session.getSessionState()
        .then(sessionState => {
            if (sessionState) {
                logoutCall();
            }
            return new Promise<void>((resolve) => { resolve(); });
        })
        .finally(() => {
            return Session.setSessionState(null);
        });
};

export const refreshLogin = (): Promise<ISessionState> => {
    return authorizationHeaderRefreshTokenValue().then(i => {
        const requestOptions = {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=utf-8",
                "Authorization": i,
                "x-login-origin": "planner"
            }
        };
        return fetch(appConfig.rootUrl + "api/token/refresh", requestOptions)
            .then(handleResponse, handleError)
            .catch(err => {
                if (err?.status === 401) {
                    customHistory.push("/login");
                }
            })
            .then(data => {
                if (!data || !data.accessToken) {
                    return null;
                }
                const sessionState = new SessionState(data);
                appConfig.culture = sessionState.culture;
                Translations.setCulture(appConfig.culture);
                CustomStore.customStore.dispatch(StoreActions.setOwnerState(sessionState.ownerName, sessionState.ownerName2, sessionState.ownerParameters));
                CustomStore.customStore.dispatch(StoreActions.setUserState(sessionState.id, sessionState.isOwnerAdmin, sessionState.ssoIntegrations));
                return Session.setSessionState(sessionState).then(() => {
                    return sessionState;
                });
            });
    });
};

export const refreshAuthentication = (force: boolean = false) : Promise<void> => {
    if (force) {
        return refreshLogin().then(() => { });
    } else {
        return Session.tokenNeedsRefresh().then(refresh => {
            if (refresh) {
                return refreshLogin().then(() => { });
            } else {
                return new Promise((resolve) => {
                    resolve();
                });
            }
        });
    }
};

export const getApiCall = <T>(url: string, type: (new (...args: any[]) => T), signal?: AbortSignal): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(null).then(i => {
            const requestOptions = {
                headers: i,
                signal
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {

                    /* eslint-disable new-cap */
                    return type
                        ? !data ? new type() : new type(data)
                        : !data ? <T><unknown>0 : <T>(data);
                    /* eslint-enable new-cap */

                });
        });
    });
};

export const getFileApiCall = (url: string): Promise<string> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(null).then(i => {
            const requestOptions = {
                headers: i
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleFileResponse, handleError);
        });
    });
};

export const postFileApiCall = (url: string, body: string = null): Promise<string> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(body).then(i => {
            const requestOptions = {
                method: "POST",
                headers: i,
                body: body
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleFileResponse, handleError);
        });
    });
};

export const postFormDataFileApiCall = (url: string, data: FormData): Promise<string> => {
    return refreshAuthentication().then(() => {
        return getFormDataHeaders().then(i => {
            const requestOptions = {
                method: "POST",
                headers: i,
                body: data
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleFileResponse, handleError);
        });
    });
};

export const postFileBlobApiCall = (url: string, body: string = null): Promise<Blob> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(body).then(i => {
            const requestOptions = {
                method: "POST",
                headers: i,
                body: body
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleFileBlobResponse, handleError);
        });
    });
};

export const postApiCall = <T>(url: string, type: (new (...args: any[]) => T), body: string = null, signal?: AbortSignal): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(body).then(i => {
            const requestOptions = body
                ? {
                    method: "POST",
                    headers: i,
                    body: body,
                    signal: signal
                }
                : {
                    method: "POST",
                    headers: i,
                    signal: signal
                };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    // ReSharper disable InconsistentNaming
                    /* eslint-disable new-cap */
                    return type
                        ? !data ? new type() : new type(data)
                        : !data ? <T><unknown>0 : <T>(data.result);
                    /* eslint-enable new-cap */
                    // ReSharper restore InconsistentNaming
                });
        });
    });
};

export const postFormApiCall = <T>(url: string, data: FormData, type: (new (...args: any[]) => T)): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getFormDataHeaders().then(i => {
            const requestOptions = {
                method: "POST",
                headers: i,
                body: data
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    // ReSharper disable InconsistentNaming
                    /* eslint-disable new-cap */
                    return !data ? new type() : new type(data);
                    /* eslint-enable new-cap */
                    // ReSharper restore InconsistentNaming
                });
        });
    });
};

export const makeFormApiCall = <T>(url: string, method: string, data: FormData, type: (new (...args: any[]) => T)): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getFormDataHeaders().then(i => {
            const requestOptions = {
                method: method,
                headers: i,
                body: data
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    return !data ? new type() : new type(data);
                });
        });
    });
};

/**
 * Make api call using what ever class for calling.
 *
 * @param url The url to call
 * @param method The method.
 * @param data The data - any kind class that back end support
 * @param type The type of return.
 */
export const makeApiCallWithAny = <T>(url: string, method: string, data: any, type: (new (...args: any[]) => T)): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getFormDataHeaders().then(i => {
            i["Content-Type"] = "application/json;charset=utf-8";

            const requestOptions = {
                method: method,
                headers: i,
                body: data ? JSON.stringify(data) : undefined
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    return !data && type ? new type() : <T>data;
                });
        });
    });
};

export const makeApiCall = <T>(url: string, method: string, data: any): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getFormDataHeaders().then(i => {
            const requestOptions: { method: string, headers: any, body?: string } = {
                method: method,
                headers: i
            };
            if (data) {
                requestOptions.body = JSON.stringify(data)
            }
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    return data;
                });
        });
    });
};

export const ApiCall = <T>(url: string,method: string, type: (new (...args: any[]) => T)): Promise<T> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(null).then(i => {
            const requestOptions = {
                method: method,
                headers: i
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    // ReSharper disable InconsistentNaming
                    /* eslint-disable new-cap */
                    return type
                        ? !data ? new type() : new type(data)
                        : !data ? <T><unknown>0 : <T>(data);
                    /* eslint-enable new-cap */
                    // ReSharper restore InconsistentNaming
                });
        });
    });
};

export const postEndlessListApiCall = <T>(url: string, type: (new (...args: any[]) => T), body: string = null): Promise<IEndlessList<T>> => {
    return refreshAuthentication().then(() => {
        return getJsonHeaders(body).then(i => {
            const requestOptions = body
                ? {
                    method: "POST",
                    headers: i,
                    body: body
                }
                : {
                    method: "POST",
                    headers: i
                };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then(handleResponse, handleError)
                .then(data => {
                    return !data ? new EndlessList(null, type) : new EndlessList(data, type);
                });
        });
    });
};

// Response can be file, class or simple type
export const postDynamicFormApiCall = <T>(url: string, data: FormData, type: (new (...args: any[]) => T)): Promise<T | string> => {
    let fileResponse = false;
    return refreshAuthentication().then(() => {
        return getFormDataHeaders().then(i => {
            const requestOptions = {
                method: "POST",
                headers: i,
                body: data
            };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then((response) => {
                    fileResponse = isFileResponse(response);
                    if (fileResponse) {
                        return handleFileResponse(response);
                    } else {
                        return handleResponse(response);
                    }
                }, handleError)
                .then(data => {
                    if (fileResponse) return data;
                    // ReSharper disable InconsistentNaming
                    /* eslint-disable new-cap */
                    return !data ? new type() : new type(data);
                    /* eslint-enable new-cap */
                    // ReSharper restore InconsistentNaming
                });
        });
    });
};

// Response can be file, class or simple type
export const postDynamicApiCall = <T>(url: string, type: (new (...args: any[]) => T), body: string = null): Promise<T | string> => {
    let fileResponse = false;
    return refreshAuthentication().then(() => {
        return getJsonHeaders(body).then(i => {
            const requestOptions = body
                ? {
                    method: "POST",
                    headers: i,
                    body: body
                }
                : {
                    method: "POST",
                    headers: i
                };
            return fetch(appConfig.rootUrl + url, requestOptions)
                .then((response) => {
                    fileResponse = isFileResponse(response);
                    if (fileResponse) {
                        return handleFileResponse(response);
                    } else {
                        return handleResponse(response);
                    }
                }, handleError)
                .then(data => {
                    if (fileResponse) return data;
                    // ReSharper disable InconsistentNaming
                    /* eslint-disable new-cap */
                    return type
                        ? !data ? new type() : new type(data)
                        : !data ? <T><unknown>0 : <T>(data.result);
                    /* eslint-enable new-cap */
                    // ReSharper restore InconsistentNaming
                });
        });
    });
};

/**
 * Trust this function to catch error response. Instead, use .catch(showApiError) to easily display errors from the
 * server. Ideally, we should intercept all error responses and display the error messages somewhere in here.
 */
function handleError(error) {
    return Promise.reject(error && error.message);
}
