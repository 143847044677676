import { IWorkHoursItem } from "../workTime/workHoursItem";

export interface WorkHourSaveData {
    employeeIds: string[];
    salaryPeriodId: string;
    workHours: WorkHourSaveItem[];
    workShiftTimeSlotIds: string[];
}

export interface WorkHourSaveItem {
    employeeId: string;
    category: string;
    salaryRowTypeId?: string;
    workOrderId?: string;
    costCenterId?: string;
    vehicleId?: string;
    workShiftId?: string;
    workShiftTimeSlotId?: string;
    dayBookingId?: string;
    workTimeTypeId?: string;
    date: string;
    amount: number;
    dayHoursRatio?: number;
    comment?: string;
}

export const getWorkHourSaveItem = ({
    employeeId,
    category,
    salaryRowTypeId,
    workOrderId,
    costCenterId,
    vehicleId,
    workShiftId,
    workShiftTimeSlotId,
    dayBookingId,
    workTimeTypeId,
    dateStr,
    amount,
    dayHoursRatio,
    comment,
}: IWorkHoursItem): WorkHourSaveItem => ({
    employeeId,
    category,
    salaryRowTypeId,
    workOrderId,
    costCenterId,
    vehicleId,
    workShiftId,
    workShiftTimeSlotId,
    dayBookingId,
    workTimeTypeId,
    date: dateStr,
    amount,
    dayHoursRatio,
    comment,
});
