import { getApiCall, makeApiCallWithAny, makeApiCall } from "./tokenService";
import { WorkTimeTypeListItem } from "../models/workTimeType/workTimeTypeListItem";
import { SaveSuccess } from "./baseService";
import { WorkTimeTypeEditItem } from "../models/workTimeType/workTimeTypeEditItem";
import { CodeTitle } from "../models/common/codeTitle";

export const getWorkTimeTypes = (): Promise<WorkTimeTypeListItem[]> => {
    return getApiCall<WorkTimeTypeListItem[]>("api/worktimetypes", null);
};

export const saveWorkTimeType = (data: WorkTimeTypeEditItem): Promise<SaveSuccess> => {
    return makeApiCallWithAny<SaveSuccess>("api/worktimetype/save", "POST", data, SaveSuccess);
};
export const deleteWorkTimeType = (workTimeTypeId: string): Promise<boolean> => {
    return makeApiCall<boolean>(
        `api/worktimetype/delete/${encodeURIComponent(workTimeTypeId)}`,
        "DELETE",
        null
    );
};
export const initializeWorkTimeTypes = (): Promise<WorkTimeTypeListItem[]> => {
    return makeApiCallWithAny<WorkTimeTypeListItem[]>("api/worktimetype/initialize", "POST", {}, null);
};

export const getWorkTimeTypeTypes = (signal?: AbortSignal): Promise<CodeTitle[]> => {
    return getApiCall<CodeTitle[]>("api/worktimetype/types", null, signal);
};
