import * as React from "react";
import { WorkShiftTimeSlotTypeCategoryWorkTime } from "../../models/common/enums";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";

// WorkTimeDialogBreak
export interface IWorkTimeDialogBreakProp {
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    workShiftTimeSlotTypeId: string;
}

export interface IWorkTimeDialogState {
    isLunchBreak: boolean;
    workShiftTimeSlotTypeTitle: string;
}

export class WorkTimeDialogBreak extends React.Component<IWorkTimeDialogBreakProp, IWorkTimeDialogState> {
    getStateFromProps = (props: IWorkTimeDialogBreakProp): IWorkTimeDialogState => {
        const workShiftTimeSlotType = props.workShiftTimeSlotTypes.find(i => i.id === props.workShiftTimeSlotTypeId);
        return {
            isLunchBreak: workShiftTimeSlotType && workShiftTimeSlotType.category === WorkShiftTimeSlotTypeCategoryWorkTime.LunchBreak,
            workShiftTimeSlotTypeTitle: workShiftTimeSlotType ? workShiftTimeSlotType.title : ""
        };
    };

    constructor(props: IWorkTimeDialogBreakProp) {
        super(props);
        this.state = this.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: IWorkTimeDialogBreakProp, prevState: IWorkTimeDialogState): void {
        const props = this.props;
        if (prevProps.workShiftTimeSlotTypeId === props.workShiftTimeSlotTypeId) return;
        this.setState(this.getStateFromProps(props));
    }

    render() {
        const state = this.state;
        return (
            <div className="workTimeType">
                <div className="firstLine">
                    <div className="iconContainer"><span className={"icon img " + (state.isLunchBreak ? "breakLunch" : "break")} /></div>
                    <div className="name">{state.workShiftTimeSlotTypeTitle}</div>
                </div>
            </div>);
    }
}