import { getApiCall, postApiCall } from "./tokenService";
import { DayBookingItemBeta } from "../models/dayBooking/dayBookingItemBeta";
import { DayBookingSaveData } from "../models/dayBooking/dayBookingSaveData";

export const getDayBookingItems = (startDate: string, endDate: string, employeeIds: string | string[], signal?: AbortSignal): Promise<DayBookingItemBeta[]> => {
    return getApiCall<DayBookingItemBeta[]>("api/daybooking/?startDate=" + (startDate || "") + "&endDate=" + (endDate || "") +
        ([employeeIds].flat().map(id => `&employeeIds=${encodeURIComponent(id)}`).join("")), Array, signal).then((data) => {
        return data.flat(1).map((item) => new DayBookingItemBeta(item));
    });
};

export const saveDayBooking = (
    data: DayBookingSaveData
): Promise<DayBookingItemBeta> => {
    return postApiCall<DayBookingItemBeta>(
        "api/daybooking/save",
        DayBookingItemBeta,
        JSON.stringify(data)
    );
};