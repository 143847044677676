import { Experimental_CssVarsProvider as CssVarsProvider, } from "@mui/material/styles";
import * as React from "react";
import { Dispatch, ReactNode } from "react";
import { Provider, connect } from "react-redux";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { EmptyObject } from "@reduxjs/toolkit";
import { BrowserRouter, Redirect, Route, Router, Switch } from "react-router-dom";
import AcknowledgementMainConnected from "../../containers/acknowledgement/acknowledgementMainConnected";
import { LoginPageConnected } from "../../containers/login/loginPageConnected";
import SettingsMainConnected from "../../containers/settings/settingsMainConnected";
import WorkMainConnected from "../../containers/work/workMainConnected";
import WorkPageMainConnected from "../../containers/work/workPageMainConnected";
import WokTimePageMainConnected from "../../containers/workTime/workTimePageMainConnected";
import { customHistory } from "../../framework/customHistory";
import * as store from "../../framework/customStore";
import { theme } from "../../framework/theme";
import { ITypedOption } from "../../models/common/typedOption";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { Session } from "../../models/session/session";
import { ISessionStateSsoIntegration } from "../../models/session/sessionStateSsoIntegration";
import * as storeActions from "../../models/store/storeActions";
import * as storeEffects from "../../models/store/storeEffects";
import { IApplicationState, IOwnerState, IUserState } from "../../models/store/storeTypes";
import { TelematicsMain } from "../telematics/telematicsMain";
import { TrackingMain } from "../tracking/trackingMain";
import { TransportOrderMain } from "../transport/transportOrder/TransportOrderMain";
import { TransportOrdersMain } from "../transport/TransportOrdersMain";
import { TransportPlanningMain } from "../transport/TransportPlanningMain";
import { TransportVehiclesMain } from "../transport/TransportVehiclesMain";
import { TransportPlanMain } from "../transport/transportPlan/transportPlanMain";
import { Header } from "./header";
import { PrivateRoute } from "./privateRoute";
import { StorageProductsMain } from "../storage/storageProductsMain";
import { StorageStoragesMain } from "../storage/storageStoragesMain";
import { StorageLocationsMain } from "../storage/storageLocationsMain";
import ReportingWorkTime from "../reporting/reportingWorkTime";
import { WorkTimeBetaBase } from "../workTimeBeta/workTimeBetaBase";
import { LicenseInfo } from "@mui/x-license-pro";
import { AlertsAndPromptsConnected } from "./alertsAndPrompts";
import { InvoicingTransportOrdersMain } from "../transport/transportOrderInvoice/InvoicingTransportOrdersMain";
import { TransportOrderInvoicesMain } from "../transport/transportOrderInvoice/TransportOrderInvoicesMain";
import { TransportOrderInvoiceContainer } from "../transport/transportOrderInvoice/TransportOrderInvoiceContainer";
import { CalculationMain } from "../calculation/CalculationMain";
import { TransportPlanRecurringEditMain } from "../transport/transportPlan/transportPlanRecurringEditMain";
import { TransportEmployeesMain } from "../transport/TransportEmployeesMain";
import { FrontPageMain } from "../frontPage/frontPageMain";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { MuiSnackBar } from "./muiSnackBar";
import { ServiceWorkerHandler } from "./serviceWorkerHandler";
import { SalariesMain } from "../salaries/salariesMain";

const rootUrl = process.env.REACT_APP_ROOT_URL;
const ownUrl = process.env.REACT_APP_OWN_URL;

appConfig = {
    rootUrl: rootUrl??"/",
    apiRootUrl: process.env.REACT_APP_API_ROOT_URL,
    ownRoot: ownUrl??"/",
    culture: "fi",
    style: "",
    authenticationTimeout: 120,
    longPressMs: 750,
    clientDatabaseName: process.env.REACT_APP_CLIENT_DATABASE_NAME ?? "LogidataWeb_Dev",
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
    environmentName: process.env.REACT_APP_ENVIRONMENT_NAME ?? "",
    environmentColor: process.env.REACT_APP_ENVIRONMENT_COLOR ?? "#FF0000",
    buildDateStr: "",
    ownerParameters: "",
    ssoIntegrations: undefined
};

export interface IAppStateProps {
    owner: IOwnerState;
    user: IUserState;
}

export interface IAppDispatchProps {
    setOwnerState: (name: string, name2: string, parameters: ITypedOption[]) => void;
    setUserState: (employeeId: string, ownerAdmin: boolean, ssoIntegrations: ISessionStateSsoIntegration[]) => void;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
}

export interface IAppProps {
    componentToTest?: ReactNode;
}

type AppProps = IAppStateProps & IAppDispatchProps & IAppProps
LicenseInfo.setLicenseKey("e6ab655b8a91361d82c1da78f8e878dfTz02Nzk0MCxFPTE3MTc1ODQ2NDY3MjcsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");
export class App extends React.Component<AppProps> {
    private sessionCheckInterval: number;

    clearSessionCheckTimeout = () => {
        if (this.sessionCheckInterval === 0) return;
        clearInterval(this.sessionCheckInterval);
        this.sessionCheckInterval = 0;
    };

    resetSessionCheckTimeout() {
        this.clearSessionCheckTimeout();
        this.sessionCheckInterval = window.setInterval(() => {
            void Session.tokenIsExpired().then((isExpired) => {
                if (!isExpired) return;
                const pathname = location.pathname.toLowerCase();
                if (pathname.indexOf("/login") > -1 || pathname.indexOf("/setpassword") > -1 || pathname.indexOf("/resetpassword") > -1) return;
                this.props.onLogout();
            });
        }, 30 * 1000);
    }

    componentDidMount(): void {
        void Session.getSessionState()
            .then((sessionState) => {
                if (sessionState) {
                    this.props.setOwnerState(sessionState.ownerName,
                        sessionState.ownerName2,
                        sessionState.ownerParameters);
                    this.props.setUserState(sessionState.id,
                        sessionState.isOwnerAdmin,
                        sessionState.ssoIntegrations);
                }
            });
        this.resetSessionCheckTimeout();
    }

    render() {
        const props = this.props;

        // Solely for tests; render tested component inside BrowserRouter
        if(props?.componentToTest) return (<BrowserRouter>{ props.componentToTest }</BrowserRouter>);

        const ownerParams = new OwnerParameters();
        return (
            <div className={appConfig.style}>
                <Router history={customHistory}>
                    <Header
                        setEmployeeParameters={props.setEmployeeParameters}
                        onLogout={props.onLogout}
                    />

                    <Route component={ServiceWorkerHandler} />

                    <Switch>
                        <Route path="/login" component={LoginPageConnected}/>
                        <PrivateRoute exact path="/" component={FrontPageMain}/>
                        {/*TODO acknowledgement can be removed?*/}
                        <Route exact path="/acknowledgement/:acknowledgementId" component={AcknowledgementMainConnected}/>
                        {ownerParams.getOwnerHasWorkOrdersEnabled() && [
                            <PrivateRoute exact path="/work" key="1" component={WorkMainConnected}/>,
                            <Route exact path="/invoicing" key="2" render={() => <Redirect to="/invoicing/workpage" />}/>,
                            <PrivateRoute exact path="/invoicing/workpage" key="3" component={WorkPageMainConnected }/>,
                            <PrivateRoute exact path="/invoicing/workpage/:page/:state" key="4" component={WorkPageMainConnected }/>
                        ]}

                        <Route exact path="/invoicingbeta" render={() => <Redirect to="/invoicingbeta/transportorders" />}/>
                        <PrivateRoute exact path="/invoicingbeta/transportorders" component={InvoicingTransportOrdersMain}/>
                        <PrivateRoute exact path="/invoicingbeta/transportorderinvoices" component={TransportOrderInvoicesMain}/>
                        <PrivateRoute exact path="/invoicingbeta/transportorderinvoice/:id" component={TransportOrderInvoiceContainer} />
                        <PrivateRoute exact path="/worktimepage/salaries/archive" component={WokTimePageMainConnected}/>
                        <PrivateRoute exact path="/worktimepage/salaries" component={SalariesMain}/>
                        <PrivateRoute path="/worktimepage/calculation/" component={CalculationMain}/>
                        <PrivateRoute path="/worktimepage" component={WorkTimeBetaBase}/>
                        <PrivateRoute exact path="/settings" component={SettingsMainConnected}/>
                        <PrivateRoute exact path="/settings/:submenu/:menu" component={SettingsMainConnected}/>
                        <PrivateRoute exact path="/tracking" component={TrackingMain}/>
                        <PrivateRoute exact path="/telematics" component={TelematicsMain} />
                        <PrivateRoute exact path={["/storage", "/storage/product"]} component={StorageProductsMain}/>
                        <PrivateRoute exact path="/storage/storage" component={StorageStoragesMain}/>
                        <PrivateRoute exact path="/storage/location" component={StorageLocationsMain}/>
                        <PrivateRoute exact path="/telematics" component={TelematicsMain} />
                        <Route exact path="/transport" render={() => <Redirect to="/transport/vehicles" />}/>
                        <PrivateRoute exact path={"/transport/vehicles"} component={TransportVehiclesMain}/>
                        <PrivateRoute exact path={"/transport/plans"} component={TransportPlanningMain}/>
                        <PrivateRoute exact path={"/transport/employees"} component={TransportEmployeesMain}/>
                        <PrivateRoute exact path={"/transport/plans/:tab"} component={TransportPlanningMain}/>
                        <PrivateRoute exact path={"/transport/orders"} component={TransportOrdersMain}/>
                        <PrivateRoute exact path={"/transport/orders/:id"} component={TransportOrderMain}/>
                        <PrivateRoute exact path={"/transport/plans/plan/:id"} component={TransportPlanMain}/>
                        <PrivateRoute exact path={"/transport/plans/template/:id"} component={TransportPlanRecurringEditMain} />
                        <PrivateRoute exact path={["/reporting", "/reporting/worktime"]} component={ReportingWorkTime} />
                        <PrivateRoute exact path="/reporting/salary" component={ReportingWorkTime}/>
                        <Route>
                            <Redirect to="/"/>
                        </Route>
                    </Switch>
                </Router>
                <MuiSnackBar />
            </div>
        );
    }
}

export function mapStateToProps(state: IApplicationState): IAppStateProps {
    return {
        owner: state.owner,
        user: state.user,
    };
}

export function mapDispatchToProps(dispatch: Dispatch<any>): IAppDispatchProps {
    return {
        setOwnerState: (name: string, name2: string, parameters: ITypedOption[]) => dispatch(storeActions.setOwnerState(name, name2, parameters)),
        setUserState: (employeeId: string, ownerAdmin: boolean, ssoIntegrations: ISessionStateSsoIntegration[]) => dispatch(storeActions.setUserState(employeeId, ownerAdmin, ssoIntegrations)),
        setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => dispatch(storeEffects.setEmployeeParameters(parameters, saveToDb)),
        onLogout: () => dispatch(storeEffects.logout()),
    };
}

export const AppConnected = connect<IAppStateProps, IAppDispatchProps>(mapStateToProps, mapDispatchToProps)(App);


if (!window.Promise) {
    window.Promise = Promise;
}

if ("geolocation" in navigator) {
    /* geolocation is available */
} else {
    console.log("[App] geolocation IS NOT available");
}

export interface ConnectedAppProps {
    storeProps?: ToolkitStore<EmptyObject>;
    componentToTest?: ReactNode;
}

export const ConnectedApp = ({ storeProps, componentToTest } : ConnectedAppProps) => (
    <Provider store={storeProps ?? store.customStore}>
        <CssVarsProvider theme={theme}>
            <AppConnected componentToTest={componentToTest} />
            <AlertsAndPromptsConnected/>
        </CssVarsProvider>
    </Provider>
);
