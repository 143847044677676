import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { AlertSeverity } from "../models/common/enums";

export enum AlertType {
    AppUpdate = "AppUpdate",
}

export interface IAlertState {
    severity?: AlertSeverity;
    message: string;
    duration?: number;
    debounce?: boolean;
    type?: AlertType;
    dialog?: boolean;
}

export interface AppOption {
    code: string;
}

export interface AppOptionsState {
    loading: boolean;
}

export enum WorkTimeTypeDrawerState {
    PendingOpen = "PendingOpen",
    PendingClose = "PendingClose",
    None = "None",
}

export enum AppUpdateState {
    None = "None",
    Pending = "Pending",
    Confirmed = "Confirmed",
}

export interface AppState {
    alerts: IAlertState[];
    update: AppUpdateState;
}

const initialState: AppState = {
    alerts: [],
    update: AppUpdateState.None,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        addAlert: (state, action: PayloadAction<IAlertState>) => {
            // Remove existing permanent (duration === null) alerts
            // to avoid stacking multiple alerts.
            state.alerts = state.alerts.filter((a) => a.duration !== null);

            if (
                !action.payload.debounce ||
                !state.alerts.find((a) => isEqual(a, action.payload))
            ) {
                state.alerts = [...state.alerts, action.payload];
            }
        },
        removeAlert: (state) => {
            state.alerts.splice(0, 1);
        },
        setAppUpdate: (state, action: PayloadAction<AppUpdateState>) => {
            state.update = action.payload;
        },
    },
});

export const { addAlert, removeAlert, setAppUpdate } = appSlice.actions;

export const appReducer = appSlice.reducer;
