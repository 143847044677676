import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IWorkHoursItem } from "../models/workTime/workHoursItem";
import {
    getCalculatedWorkHours,
    getNormalWorkHours,
} from "../services/workHoursService";
import { Base } from "../framework/base";

export interface WorkHoursState {
    normal: IWorkHoursItem[];
    calculated: IWorkHoursItem[];
}

const initialState: WorkHoursState = {
    normal: [],
    calculated: [],
};

export const workHoursSlice = createSlice({
    name: "workHours",
    initialState,
    reducers: {
        setNormalWorkHours: (
            state,
            action: PayloadAction<IWorkHoursItem[]>
        ) => {
            state.normal = action.payload;
        },
        calculatedWorkHoursReceived: (
            state,
            action: PayloadAction<{
                workHours: IWorkHoursItem[];
                employeeId: string;
                startDate: string;
                endDate: string;
            }>
        ) => {
            // Preserve existing items for non-present employeeIds
            // or outside of given range
            state.calculated = state.calculated.filter(
                (h) =>
                    action.payload.employeeId !== h.employeeId ||
                    h.dateStr < action.payload.startDate ||
                    h.dateStr > action.payload.endDate
            );
            state.calculated = [
                ...state.calculated,
                ...action.payload.workHours,
            ];
        },
    },
});

export const { setNormalWorkHours, calculatedWorkHoursReceived } =
    workHoursSlice.actions;

export const normalWorkHours = createAsyncThunk(
    "workHours/normalWorkHours",
    async (
        {
            employeeId,
            startDate,
            endDate,
        }: {
            employeeId: string;
            startDate: string;
            endDate: string;
        },
        thunkApi
    ) => {
        const workHoursItems = await getNormalWorkHours(
            employeeId,
            startDate,
            endDate,
            thunkApi.signal
        );
        thunkApi.dispatch(setNormalWorkHours(workHoursItems));
    }
);

export const calculatedWorkHours = createAsyncThunk(
    "workHours/calculatedWorkHours",
    async (
        {
            employeeGroupId,
            employeeId,
            startDate,
            endDate,
        }: {
            employeeGroupId?: string;
            employeeId: string;
            startDate: string;
            endDate: string;
        },
        thunkApi
    ) => {
        const workHours = await getCalculatedWorkHours(
            employeeGroupId,
            employeeId,
            startDate,
            endDate,
            false,
            thunkApi.signal
        );
        thunkApi.dispatch(
            calculatedWorkHoursReceived({
                workHours,
                employeeId,
                startDate,
                endDate,
            })
        );
    }
);

export const workHoursReducer = workHoursSlice.reducer;

const makeSelectWorkHoursByDate = (normal: boolean) =>
    createSelector(
        (state: WorkHoursState) => (normal ? state.normal : state.calculated),
        (_, employeeId: string) => employeeId,
        (_, _employeeId, rangeStart: string) => rangeStart,
        (_, _employeeId, _rangeStart, rangeEnd: string) => rangeEnd,
        (
            _,
            _employeeId,
            _rangeStart,
            _rangeEnd: string,
            categories?: string[]
        ) => categories,
        (
            items: IWorkHoursItem[],
            employeeId: string,
            rangeStart: string,
            rangeEnd: string,
            categories: string[]
        ) => {
            if (rangeStart && rangeEnd) {
                return items.filter(
                    (i) =>
                        i.employeeId === employeeId &&
                        (!categories || categories.includes(i.category)) &&
                        Base.isBetween(
                            i.dateStr,
                            rangeStart,
                            rangeEnd,
                            "day",
                            "[]"
                        )
                );
            }
        }
    );

export const makeSelectWorkHoursAmountByDate = (normal: boolean) =>
    createSelector([makeSelectWorkHoursByDate(normal)], (items) =>
        items ? items.reduce((acc, cur) => acc + cur.amount, 0) : undefined
    );

export const makeSelectNormalWorkHoursByDate = () =>
    makeSelectWorkHoursByDate(true);
export const makeSelectCalculatedWorkHoursByDate = () =>
    makeSelectWorkHoursByDate(false);
export const makeSelectNormalWorkHoursAmountByDate = () =>
    makeSelectWorkHoursAmountByDate(true);
export const makeSelectCalculatedWorkHoursAmountByDate = () =>
    makeSelectWorkHoursAmountByDate(false);
