// WorkTimeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as workTimeService from "../../services/workTimeService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { IWorkTimeEdit } from "../../models/workTime/workTimeEdit";
import { Radio } from "../framework/radio";
import { WorkTimeDialogWorkOrder } from "./workTimeDialogWorkOrder";
import { WorkTimeDialogCostCenter } from "./workTimeDialogCostCenter";
import { WorkOrderCategory, WorkShiftTimeSlotTypeCategoryWorkTime } from "../../models/common/enums";
import { WorkTimeDialogBreak } from "./workTimeDialogBreak";
import { DatePicker } from "../framework/datePicker";
import { TimeSelector } from "../framework/timeSelector";
import { SelectWorkOrderDialog } from "../work/selectWorkOrderDialog";
import { IWorkOrderListItem } from "../../models/work/workOrderListItem";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { WorkShiftTimeSlotType, IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { ObjectEventLogList } from "../eventLog/objectEventLogList";
import { AppUtils } from "../../models/common/appUtils";
import moment from "moment";

// WorkTimeDialog
// ***********************************************************************************************************************
export interface IWorkTimeDialogProp {
    classes?: string;
    editItem: IWorkTimeEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface IWorkTimeDialogState {
    vehicles: IIdTitle[];
    breakWorkShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    workShiftTimeSlotTypeIdWork: string;
    isReadonly: boolean;
    workShiftTimeSlotTypeId: string;
    workShiftTimeSlotTypeTitle: string;
    vehicleId: string;
    employeeId: string;
    workOrderId: string;
    costCenterId: string;
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
    duration: number;
    description: string;
    workOrderNumber: number;
    workOrderParentNumber: number;
    workOrderState: number;
    workOrderName: string;
    workOrderDescription: string;
    workOrderCategory: number;
    costCenterName: string;
    costCenterDescription: string;
    showSelectWorkOrderDialog: boolean;
    showSelectCostCenterDialog: boolean;
}

export class WorkTimeDialog extends React.Component<IWorkTimeDialogProp, IWorkTimeDialogState> {
    private static orgStateHash = "";

    constructor(props: IWorkTimeDialogProp) {
        super(props);
        const workTime = props.editItem.workTime;
        const vehicles = props.editItem.vehicles.slice(0);
        if (vehicles.length > 0) {
            vehicles.unshift(IdTitle.createIdTitle("", ""));
        }
        const costCenter = workTime.costCenterId ? props.editItem.costCenters.find(i => i.id === workTime.costCenterId) : null;
        this.state = {
            isReadonly: workTime.isLocked(),
            vehicles: vehicles,
            breakWorkShiftTimeSlotTypes: props.editItem.workShiftTimeSlotTypes.filter(i => !i.isCategory(WorkShiftTimeSlotTypeCategoryWorkTime.Work)),
            workShiftTimeSlotTypeIdWork: WorkShiftTimeSlotType.getIdByCategory(props.editItem.workShiftTimeSlotTypes, WorkShiftTimeSlotTypeCategoryWorkTime.Work),
            vehicleId: workTime.vehicleId,
            employeeId: workTime.employeeId,
            workOrderId: workTime.workOrderId,
            costCenterId: workTime.costCenterId,
            workShiftTimeSlotTypeId: workTime.workShiftTimeSlotTypeId,
            workShiftTimeSlotTypeTitle: IdTitle.getTitleById(props.editItem.workShiftTimeSlotTypes, workTime.workShiftTimeSlotTypeId),
            startDateStr: Base.timeToDateStr(workTime.startTime),
            startTimeStr: Base.timeToTimeStr(workTime.startTime),
            endDateStr: workTime.endTime ? Base.timeToDateStr(workTime.endTime) : "",
            endTimeStr: workTime.endTime ? Base.timeToTimeStr(workTime.endTime) : "",
            duration: Math.max(0, Base.dateDiffInMinutes(new Date(workTime.startTime), new Date(workTime.endTime))),
            description: workTime.description,
            workOrderNumber: workTime.workOrderNumber,
            workOrderParentNumber: workTime.workOrderParentNumber,
            workOrderState: workTime.workOrderState,
            workOrderName: workTime.workOrderName,
            workOrderDescription: workTime.workOrderDescription,
            workOrderCategory: workTime.workOrderCategory,
            costCenterName: costCenter ? costCenter.title : "",
            costCenterDescription: costCenter ? costCenter.description : "",
            showSelectWorkOrderDialog: false,
            showSelectCostCenterDialog: false
        };
        const saveData = WorkTimeDialog.getSaveDataFromState(props, this.state);
        WorkTimeDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "description") {
            this.setState({ description: value });
        } else if (name === "vehicleId") {
            this.setState({ vehicleId: value });
        } else if (name === "employeeId") {
            this.setState({ employeeId: value });
        }
    };

    changeStartDate = (value: string) => {
        const state = this.state;
        let startTimeStr = state.startTimeStr;
        if (!startTimeStr) {
            startTimeStr = Base.timeToTimeStr(new Date(2000, 0, 0).getTime());
        }
        this.setState({ startDateStr: value, startTimeStr: startTimeStr });
    };

    getDateTime = (dateStr: string, timeStr: string): Date => {
        let result: Date = null;
        if (dateStr) {
            result = dateStr.toDate();
            if (timeStr) {
                const startTime = timeStr.toTime();
                result = result.addHours(startTime.getHours()).addMinutes(startTime.getMinutes());
            }
        }
        return result;
    };

    startDateTimeChange = (startDateTime: Date, endDateTime: Date, duration: number) => {
        if (!endDateTime) {
            endDateTime = startDateTime;
        } else if (endDateTime < startDateTime) {
            if (startDateTime) {
                endDateTime = startDateTime.addMinutes(duration);
            }
        }
        this.setState({ startDateStr: Base.dateToDateStr(startDateTime), startTimeStr: Base.dateToTimeStr(startDateTime), endDateStr: Base.dateToDateStr(endDateTime), endTimeStr: Base.dateToTimeStr(endDateTime), duration: Math.max(0, Base.dateDiffInMinutes(startDateTime, endDateTime)) });
    };

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleStartDateBlur = (value: string) => {
        const state = this.state;
        this.startDateTimeChange(this.getDateTime(value, state.startTimeStr), this.getDateTime(state.endDateStr, state.endTimeStr), state.duration);
    };

    handleStartTimeChange = (value: string) => {
        const state = this.state;
        this.startDateTimeChange(this.getDateTime(state.startDateStr, value), this.getDateTime(state.endDateStr, state.endTimeStr), state.duration);
    };

    handleEndDateChange = (value: string) => {
        this.setState({ endDateStr: value });
    };

    handleEndTimeChange = (value: string) => {
        this.setState({ endTimeStr: value });
    };
    // #endregion General

    //#region TaskType
    handleTaskTypeChange = (workShiftTimeSlotTypeId: string, workOrder: boolean, costCenter: boolean) => {
        const editItem = this.props.editItem;
        if (workOrder) {
            this.setState({
                showSelectWorkOrderDialog: true
            });
        } else if (costCenter) {
            if (editItem.costCenters.length > 1) {
                this.setState({
                    showSelectCostCenterDialog: true
                });
            } else if (editItem.costCenters.length > 0) {
                this.handleSelectCostCenterDialogOk(editItem.costCenters[0].id, null);
            }
        } else {
            this.setState({
                workOrderId: "",
                costCenterId: "",
                workShiftTimeSlotTypeId: workShiftTimeSlotTypeId,
                workShiftTimeSlotTypeTitle: IdTitle.getTitleById(editItem.workShiftTimeSlotTypes, workShiftTimeSlotTypeId),
            });
        }
    };

    handleSelectWorkOrderDialogOk = (workOrders: IWorkOrderListItem[]) => {
        if (workOrders.length < 1) return;
        const state = this.state;
        const workOrder = workOrders[0];
        if (!workOrder || workOrder.id === state.workOrderId) return;
        this.setState({
            costCenterId: "",
            workShiftTimeSlotTypeId: state.workShiftTimeSlotTypeIdWork,
            workShiftTimeSlotTypeTitle: IdTitle.getTitleById(this.props.editItem.workShiftTimeSlotTypes, state.workShiftTimeSlotTypeIdWork),
            workOrderId: workOrder.id,
            workOrderNumber: workOrder.number,
            workOrderParentNumber: workOrder.parentNumber,
            workOrderState: workOrder.state,
            workOrderName: workOrder.name,
            workOrderDescription: workOrder.description,
            workOrderCategory: workOrder.category,
            showSelectWorkOrderDialog: false
        });
    };

    handleSelectWorkOrderDialogCancel = () => {
        this.setState({
            showSelectWorkOrderDialog: false
        });
    };

    handleLoadCostCenters = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const editItem = this.props.editItem;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = (!filter ? editItem.costCenters : editItem.costCenters.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1 || i.description.toLowerCase().indexOf(lowFilter) > -1)).map(i => IdTitle.createIdTitle(i.id, i.title));
            result.page = 1;
            result.hasMore = false;
            console.log(result.items);
            resolve(result);
        });
    };

    handleSelectCostCenterDialogOk = (id: string, title: string) => {
        if (!id) return;
        const state = this.state;
        const costCenter = this.props.editItem.costCenters.find(i => i.id === id);
        if (!costCenter) return;
        this.setState({
            workShiftTimeSlotTypeId: state.workShiftTimeSlotTypeIdWork,
            workShiftTimeSlotTypeTitle: IdTitle.getTitleById(this.props.editItem.workShiftTimeSlotTypes, state.workShiftTimeSlotTypeIdWork),
            costCenterId: costCenter.id,
            costCenterName: costCenter.title,
            costCenterDescription: costCenter.description,
            workOrderId: "",
            showSelectCostCenterDialog: false
        });
    };

    handleSelectCostCenterDialogCancel = () => {
        this.setState({
            showSelectCostCenterDialog: false
        });
    };
    //#endregion TaskType

    private static validate = (state: IWorkTimeDialogState): boolean => {
        if (!state.startDateStr || !state.startTimeStr) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.StartTimeMustBeDefined));
            return false;
        }
        const endDateTime = AppUtils.getDateTime(state.endDateStr, state.endTimeStr, false);
        if (endDateTime) {
            const startDateTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, false);
            const duration = Math.max(0, Base.dateDiffInMinutes(startDateTime, endDateTime));
            if (duration < 1) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.InvalidEndDate));
                return false;
            }
        }
        return true;
    };

    private static getSaveDataFromState = (props: IWorkTimeDialogProp, state: IWorkTimeDialogState): SaveData => {
        const data = new SaveData();
        const workTime = props.editItem.workTime;
        // Common
        data.append("id", workTime.id);
        data.append("rowId", workTime.rowId);
        // General
        data.append("workShiftTimeSlotTypeId", state.workShiftTimeSlotTypeId);
        data.append("employeeId", state.employeeId);
        data.append("vehicleId", state.vehicleId ? state.vehicleId : String(null));
        data.append("workOrderId", state.workOrderId ? state.workOrderId : String(null));
        data.append("costCenterId", state.costCenterId ? state.costCenterId : String(null));
        const startDateTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, false);
        const endDateTime = AppUtils.getDateTime(state.endDateStr, state.endTimeStr, false);
        data.append("startTime", startDateTime ? startDateTime.getTime().toString(10) : "");
        data.append("endTime", endDateTime ? endDateTime.getTime().toString(10) : "");
        data.append("startDate", startDateTime ? moment(startDateTime.getTime()).format() : "");
        data.append("endDate", endDateTime ? moment(endDateTime.getTime()).format() : "");
        data.append("description", state.description);
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!WorkTimeDialog.validate(this.state)) return;
        const saveData = WorkTimeDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        workTimeService.saveWorkTimeEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = WorkTimeDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== WorkTimeDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const editItem = props.editItem;
        const workTime = editItem.workTime;
        const dialogClasses = "workTime px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.WorkShiftTimeSlot + " - " + (!workTime.isNew() ? workTime.getTaskName(state.costCenterName, state.workShiftTimeSlotTypeTitle) : Translations.New) + (workTime.isLocked() ? " - " + Translations.Locked : "")}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <label className="control-label smallFont required">{Translations.Task}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <Radio
                                        classes="control-label"
                                        title={Translations.Order}
                                        enabled={!state.isReadonly}
                                        checked={!!state.workOrderId && state.workShiftTimeSlotTypeId === state.workShiftTimeSlotTypeIdWork}
                                        onRadioClickBoolean={() => { this.handleTaskTypeChange(state.workShiftTimeSlotTypeIdWork, true, false); }}
                                    />
                                    {props.editItem.costCenters.length > 0 &&
                                        <Radio
                                            classes="control-label"
                                            title={Translations.CostCenter}
                                            enabled={!state.isReadonly}
                                            checked={!!state.costCenterId && state.workShiftTimeSlotTypeId === state.workShiftTimeSlotTypeIdWork}
                                            onRadioClickBoolean={() => { this.handleTaskTypeChange(state.workShiftTimeSlotTypeIdWork, false, true); }}
                                        />
                                    }
                                    {state.breakWorkShiftTimeSlotTypes.map((workShiftTimeSlotType) =>
                                        <Radio
                                            key={workShiftTimeSlotType.id}
                                            classes="control-label"
                                            title={workShiftTimeSlotType.title}
                                            enabled={!state.isReadonly}
                                            checked={state.workShiftTimeSlotTypeId === workShiftTimeSlotType.id}
                                            onRadioClickBoolean={() => { this.handleTaskTypeChange(workShiftTimeSlotType.id, false, false); }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="form-group">
                                    {state.workOrderId &&
                                        <WorkTimeDialogWorkOrder
                                            id={state.workOrderId}
                                            state={state.workOrderState}
                                            number={state.workOrderNumber}
                                            parentNumber={state.workOrderParentNumber}
                                            name={state.workOrderName}
                                            description={state.workOrderDescription}
                                        />
                                    }
                                    {state.costCenterId &&
                                        <WorkTimeDialogCostCenter
                                            name={state.costCenterName}
                                            description={state.costCenterDescription}
                                        />
                                    }
                                    {(!state.workOrderId && !state.costCenterId) &&
                                        <WorkTimeDialogBreak
                                            workShiftTimeSlotTypes={state.breakWorkShiftTimeSlotTypes}
                                            workShiftTimeSlotTypeId={state.workShiftTimeSlotTypeId}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Employee}</label>
                                    <select className="form-control" name="employeeId" title={Translations.Employee} value={state.employeeId} onChange={this.handleChange} disabled={state.isReadonly}>
                                        {props.editItem.employees.map((employee) =>
                                            <option key={employee.id} value={employee.id}>{employee.title}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            {state.vehicles.length > 0 &&
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Vehicle}</label>
                                        <select className="form-control" name="vehicleId" title={Translations.Vehicle} value={state.vehicleId} onChange={this.handleChange} disabled={state.isReadonly}>
                                            {state.vehicles.map((vehicle) =>
                                                <option key={vehicle.id} value={vehicle.id}>{vehicle.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.StartTime}</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <DatePicker
                                                disabled={state.isReadonly}
                                                titleClasses={"smallFont"}
                                                value={state.startDateStr}
                                                required={true}
                                                onChange={this.handleStartDateChange}
                                                onBlur={this.handleStartDateBlur}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <TimeSelector
                                                disabled={state.isReadonly}
                                                disabledValue={state.startTimeStr}
                                                required={true}
                                                date={state.startDateStr}
                                                value={state.startTimeStr}
                                                onChange={this.handleStartTimeChange}
                                            />
                                        </div>
                                    </div>
                                    <span className="smallFont">
                                        {(Base.getMinuteAccuracyTime(workTime.startTime) !== Base.getMinuteAccuracyTime(workTime.orgStartTime) || Base.getMinuteAccuracyTime(workTime.endTime) !== Base.getMinuteAccuracyTime(workTime.orgEndTime)) &&
                                            <span> {String.format(Translations.OrginalTimeParameter, AppUtils.getTimeDurationStr(workTime.orgStartTime, workTime.orgEndTime, false, true, false))}</span>
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.EndDate}</label>
                                    <DatePicker
                                        disabled={state.isReadonly}
                                        titleClasses={"smallFont"}
                                        value={state.endDateStr}
                                        onChange={this.handleEndDateChange}
                                        onBlur={this.handleEndDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">&nbsp;</label>
                                    <TimeSelector
                                        date={state.endDateStr}
                                        disabled={state.isReadonly}
                                        disabledValue={state.endTimeStr}
                                        startDate={state.startDateStr}
                                        startTime={state.startTimeStr}
                                        value={state.endTimeStr}
                                        onChange={this.handleEndTimeChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Description}</label>
                                    <textarea className="form-control" name="description" title={Translations.Description} value={state.description} onChange={this.handleChange} maxLength={1000} disabled={state.isReadonly} readOnly={state.isReadonly} />
                                </div>
                            </div>
                        </div>
                        {props.editItem.eventLogs.length > 0 &&
                            <ObjectEventLogList
                                classes={"workOrderSubList"}
                                title={Translations.Events}
                                items={props.editItem.eventLogs}
                            />
                        }
                        {state.showSelectWorkOrderDialog &&
                            <SelectWorkOrderDialog
                                multiSelect={false}
                                categories={[WorkOrderCategory.Work, WorkOrderCategory.Task]}
                                title={Translations.SelectWorkOrder}
                                onOk={this.handleSelectWorkOrderDialogOk}
                                onCancel={this.handleSelectWorkOrderDialogCancel}
                            />
                        }
                        {state.showSelectCostCenterDialog &&
                            <SelectIdTitleDialog
                                title={Translations.SelectCostCenter}
                                selectedId={""}
                                listbox={false}
                                autoFilter={true}
                                loadIdCodes={this.handleLoadCostCenters}
                                onAdd={null}
                                onOk={this.handleSelectCostCenterDialogOk}
                                onCancel={this.handleSelectCostCenterDialogCancel}
                            />
                        }
                          </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
