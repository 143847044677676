import { getApiCall, postApiCall, postFormApiCall } from "./tokenService";
import { ApiSuccess } from "./baseService";
import { WorkHoursEdit } from "../models/workTime/workHoursEdit";
import { WorkHoursCalculatedHours } from "../models/workTime/workHoursCalculatedHours";
import { IWorkHoursItem } from "../models/workTime/workHoursItem";
import { WorkHourSaveData } from "../models/workHour/workHourSaveData";
import { apiCall } from "./apiClient";

export const getWorkHoursEdit = (employeeIds: string[], salaryPeriodId: string): Promise<WorkHoursEdit> => {
    return postApiCall<WorkHoursEdit>("api/workhours/edit", WorkHoursEdit, JSON.stringify({
        employeeIds: employeeIds,
        salaryPeriodId: salaryPeriodId
    }));
};

export const saveWorkHoursEdit = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workhours/save", data, ApiSuccess);
};

export const calculateDailyWorkHours = (employeeGroupId: string, employeeIds: string[], startDate: number, endDate: number, withTime?: boolean, signal?: AbortSignal): Promise<WorkHoursCalculatedHours> => {
    return postApiCall<WorkHoursCalculatedHours>("api/workhours/calculatedaily", WorkHoursCalculatedHours, JSON.stringify({
        employeeGroupId: employeeGroupId,
        employeeIds: employeeIds,
        startDate: startDate,
        endDate: endDate,
        withTime: withTime,
    }), signal);
};

export const getCalculatedWorkHours = (
    employeeGroupId: string | undefined,
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    validateMonthlySalaryPeriod = false,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${
            employeeGroupId
                ? `&employeeGroupId=${encodeURIComponent(employeeGroupId)}`
                : ""
        }&validateMonthlySalaryPeriod=${validateMonthlySalaryPeriod}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const getCalculatedWorkHoursNew = (
    employeeGroupId: string | undefined,
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    validateMonthlySalaryPeriod = false,
    signal?: AbortSignal
) => {
    return apiCall<IWorkHoursItem[]>(
        `workhours?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${
            employeeGroupId
                ? `&employeeGroupId=${encodeURIComponent(employeeGroupId)}`
                : ""
        }&validateMonthlySalaryPeriod=${validateMonthlySalaryPeriod}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        "GET",
        null,
        signal
    );
};

export const getNormalWorkHours = (
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours/normal?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${[employeeIds]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const saveWorkHours = (data: WorkHourSaveData): Promise<void> => {
    return postApiCall<void>("api/workhour/save", null, JSON.stringify(data));
};
