import { ApiSuccess } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { VehicleItems } from "../models/vehicle/vehicleItems";
import { VehicleEdit } from "../models/vehicle/vehicleEdit";
import { ApiResponse, apiCall } from "./apiClient";
import { VehiclePositionInfo, VehiclePositionQueryParams } from "../models/vehicle/vehiclePosition";

export const getVehicleItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<VehicleItems> => {
    return getApiCall<VehicleItems>("api/vehicle/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), VehicleItems);
};

export const getVehicleEdit = (id: string): Promise<VehicleEdit> => {
    return getApiCall<VehicleEdit>("api/vehicle/edit/?id=" + (id || ""), VehicleEdit);
};

export const saveVehicleEdit = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/vehicle/save", data, ApiSuccess);
};

export const removeVehicle = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/vehicle/remove/?id=" + (id || ""), ApiSuccess);
};

export const getVehiclePosition = (
    vehicleId: string,
    windowStartTime?: string,
    windowEndTime?: string,
    signal?: AbortSignal
): Promise<ApiResponse<VehiclePositionInfo[]>> => {
    return apiCall<VehiclePositionInfo[], VehiclePositionQueryParams>(
        `vehicles/${vehicleId}/position`,
        "GET",
        { windowStartTime, windowEndTime },
        signal
    );
};
