import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import moment from "moment";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { ConfirmationDialogResult, EnumHelper, RecurrenceType, StrMeasureUnit, TimeFormat } from "./enums";
import { ILocationPoint } from "./locationPoint";
import { ConfirmationDialogType } from "../store/storeTypes";
import { IApiResponse } from "../../services/baseService";
import i18n from "i18next";

export interface IWorkOrderBaseTitle {
    id: string;
    startTime: number;
    name: string;
    description: string;
    customerName: string;
    siteName: string;
    siteAddress: string;
}

export class AppUtils {
    // Converts duration expressed in minutes to string
    // Result is in format 'X d Y h Z min' where X is days, Y is hours, Z is minutes and only units > 0 are included
    static getDurationStrByDurationMin(durationMin: number, showZeroDuration: boolean = false): string {
        if (durationMin <= 0 && !showZeroDuration) return "";
        if (durationMin <= 0 && showZeroDuration) return durationMin.toString(10) + " " + Translations.AbrMins;
        const durationDay = durationMin >= 24 * 60 ? Math.floor(durationMin / (24 * 60)) : 0;
        durationMin = durationMin - 24 * 60 * durationDay;
        const durationHour = durationMin >= 60 ? Math.floor(durationMin / 60) : 0;
        durationMin = Math.round(durationMin - 60 * durationHour);
        return ((durationDay > 0 ? durationDay.toString(10) + " " + Translations.AbrDays + " " : "") +
            (durationHour > 0 ? durationHour.toString(10) + " " + Translations.AbrHours + " " : "") +
            (durationMin > 0 ? durationMin.toString(10) + " " + Translations.AbrMins : "")).trim();
    }

    // Converts duration expressed in minutes to string
    // Result is in format 'Y:ZZ' where Y is hours, Z is minutes
    static getDurationStrByDurationMinShort(durationMin: number, showZeroDuration: boolean = false): string {
        let durationMinAbs = Math.abs(Math.round(durationMin));
        if (durationMinAbs <= 0 && !showZeroDuration) return "";
        const durationHour = durationMinAbs >= 60 ? Math.floor(durationMinAbs / 60) : 0;
        durationMinAbs = Math.round(durationMinAbs - 60 * durationHour);
        return ((durationMin < 0 ? "-" : "") + (durationHour.toString(10) + ":") +
            Base.leftPad(durationMinAbs.toString(10), 2, "0")).trim();
    }

    static getDurationStr(startTime: number, endTime: number): string {
        return AppUtils.getDurationStrByDurationMin(Base.dateDiffInMinutes(startTime, endTime));
    }

    static minutesToHours(minutes: number): string {
        return (minutes / 60).toString();
    }

    private static timeToMinStr(value: number): string {
        if (!Base.timeHasValue(value)) return "";
        return moment(value).format("mm");
    }

    private static timeToHourStr(value: number): string {
        if (!Base.timeHasValue(value)) return "";
        return moment(value).format("H");
    }

    private static timeToDayStr(value: number): string {
        if (!Base.timeHasValue(value)) return "";
        return moment(value).format("D");
    }

    private static timeToMonthStr(value: number): string {
        if (!Base.timeHasValue(value)) return "";
        return moment(value).format("M");
    }

    private static timeToYearStr(value: number): string {
        if (!Base.timeHasValue(value)) return "";
        return moment(value).format("Y");
    }

    static getTimeSuffixStr(startTime: number, endTime: number, fullDay: boolean, suffix: string, includeTime: boolean = false): string {
        if (!startTime) return "";
        const startMinStr = includeTime ? AppUtils.timeToMinStr(startTime) : "";
        const startHourStr = includeTime ? AppUtils.timeToHourStr(startTime) : "";
        const startDayStr = AppUtils.timeToDayStr(startTime);
        const startMonthStr = AppUtils.timeToMonthStr(startTime);
        const startYearStr = AppUtils.timeToYearStr(startTime);
        if (!endTime) {
            return startDayStr + "." + startMonthStr + "." + startYearStr + (includeTime ? " " + startHourStr + "." + startMinStr : "") + suffix;
        }
        if (fullDay) {
            endTime = new Date(endTime).addDays(-1).getTime();
        }
        const endMinStr = includeTime ? AppUtils.timeToMinStr(endTime) : "";
        const endHourStr = includeTime ? AppUtils.timeToHourStr(endTime) : "";
        const endDayStr = AppUtils.timeToDayStr(endTime);
        const endMonthStr = AppUtils.timeToMonthStr(endTime);
        const endYearStr = AppUtils.timeToYearStr(endTime);
        if (!includeTime) {
            if (startDayStr === endDayStr && startMonthStr === endMonthStr && startYearStr === endYearStr) {
                return startDayStr + "." + startMonthStr + "." + startYearStr + suffix;
            }
            if (startMonthStr === endMonthStr && startYearStr === endYearStr) {
                return startDayStr + ".-" + endDayStr + "." + startMonthStr + "." + startYearStr + suffix;
            }
            if (startYearStr === endYearStr) {
                return startDayStr + "." + startMonthStr + ".-" + endDayStr + "." + endMonthStr + "." + startYearStr + suffix;
            }
            return startDayStr + "." + startMonthStr + "." + startYearStr + "-" + endDayStr + "." + endMonthStr + "." + endYearStr + suffix;
        } else {
            if (startDayStr === endDayStr && startMonthStr === endMonthStr && startYearStr === endYearStr && startMinStr === endMinStr && startHourStr === endHourStr) {
                return startDayStr + "." + startMonthStr + "." + startYearStr + " " + startHourStr + "." + startMinStr + suffix;
            }
            if (startDayStr === endDayStr && startMonthStr === endMonthStr && startYearStr === endYearStr && startHourStr === endHourStr) {
                return startDayStr + "." + startMonthStr + "." + startYearStr + " " + startHourStr + "." + startMinStr + "-" + endMinStr + suffix;
            }
            if (startDayStr === endDayStr && startMonthStr === endMonthStr && startYearStr === endYearStr) {
                return startDayStr + "." + startMonthStr + "." + startYearStr + " " + startHourStr + "." + startMinStr + "-" + endHourStr + "." + endMinStr + suffix;
            }
            if (startMonthStr === endMonthStr && startYearStr === endYearStr) {
                return startDayStr + ".-" + endDayStr + "." + startMonthStr + "." + startYearStr + " " + startHourStr + "." + startMinStr + "-" + endHourStr + "." + endMinStr + suffix;
            }
            if (startYearStr === endYearStr) {
                return startDayStr + "." + startMonthStr + ".-" + endDayStr + "." + endMonthStr + "." + startYearStr + " " + startHourStr + "." + startMinStr + "-" + endHourStr + "." + endMinStr + suffix;
            }
            return startDayStr + "." + startMonthStr + "." + startYearStr + "-" + endDayStr + "." + endMonthStr + "." + endYearStr + " " + startHourStr + "." + startMinStr + "-" + endHourStr + "." + endMinStr + suffix;
        }
    }

    static getTimeDurationStrByDurationMin(startTime: number, endTime: number, fullDay: boolean, durationMin: number, includeTime: boolean = false, showDuration: boolean = true): string {
        if (!startTime) return "";
        const durationStr = showDuration ? AppUtils.getDurationStrByDurationMin(durationMin) : "";
        return AppUtils.getTimeSuffixStr(startTime, endTime, fullDay, durationStr ? " (" + durationStr + ")" : "", includeTime);
    }

    static getTimeDurationStr(startTime: number, endTime: number, fullDay: boolean, includeTime: boolean = false, showDuration: boolean = true): string {
        return AppUtils.getTimeDurationStrByDurationMin(startTime, endTime, fullDay, Base.dateDiffInMinutes(startTime, endTime), includeTime, showDuration);
    }

    static getTimeDurationStrUtc(utcStartTime: number, utcEndTime: number, fullDay: boolean, includeTime: boolean = false, showDuration: boolean = true): string {
        const startTime = Base.convertUtcTimeToLocalDate(utcStartTime).getTime();
        const endTime = Base.convertUtcTimeToLocalDate(utcEndTime).getTime();
        return AppUtils.getTimeDurationStrByDurationMin(startTime, endTime, fullDay, Base.dateDiffInMinutes(startTime, endTime), includeTime, showDuration);
    }

    static getDateTime = (dateStr: string, timeStr: string, fullDay: boolean): Date => {
        let result: Date = null;
        if (dateStr) {
            result = dateStr.toDate();
            if (!fullDay && timeStr) {
                const startTime = timeStr.toTime();
                result = result.addHours(startTime.getHours()).addMinutes(startTime.getMinutes());
            }
        }
        return result;
    };

    static getTimeMeasureUnits = (): string[] => {
        return [
            StrMeasureUnit.Hour,
            StrMeasureUnit.HourFi,
        ];
    };

    static isTimeUnit = (unit: string): boolean => {
        return AppUtils.getTimeMeasureUnits().includes(unit);
    };

    static isDayUnit = (unit: string): boolean => {
        return unit === StrMeasureUnit.DayFiAlt;
    };

    static getDisplayedNumberBasedOnUnit = (amount: number, unit: string, decimals?: number, isHours?: boolean, blankIfZero?: boolean): string => {
        if (blankIfZero && !amount) return "";
        return AppUtils.isTimeUnit(unit) ?
            AppUtils.getDurationStrByDurationMinShort(amount * (isHours ? 60 : 1), true) :
            amount.toLocaleFixed(decimals || 0);
    };

    // #region workTime
    static getWorkTimeStr(timeFormat: TimeFormat, hours: number): string {
        if (!Base.numberHasValue(hours)) return "";
        return EnumHelper.isEqual(timeFormat, TimeFormat.DecimalFormat) ? hours.toLocaleFixed(1) : Base.hoursToTimeStr(hours);
    }

    static strWorkTimeToHours(timeFormat: TimeFormat, str: string): number {
        if (!str) return 0;
        return EnumHelper.isEqual(timeFormat, TimeFormat.DecimalFormat) ? str.toDecimal() : str.toTimeHours();
    }

    static strToValidWorkTimeStr(timeFormat: TimeFormat, str: string): string {
        if (!str) return "";
        return EnumHelper.isEqual(timeFormat, TimeFormat.DecimalFormat) ? str.toDecimal().toLocaleFixed(1) : Base.hoursToTimeStr(str.toTimeHours());
    }
    // #endregion workTime

    // #region workTime
    static strDurationToHours(str: string): number {
        if (!str) return 0;
        return str.toTimeSpanHours();
    }
    // #endregion workTime

    // #region workOrder
    static getWorkOrderTitle(item: IWorkOrderBaseTitle): string {
        return Base.getStringWithSeparators([item.customerName, item.name, item.description, item.siteName, item.siteAddress, Base.timeToDateStr(item.startTime)], ", ");
    }

    static getRecurrenceInfo(recurrenceType: number, recurrenceInterval: number, recurrenceIntervalDetail: number) {
        let recursInfo = "";
        if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Daily)) {
            recursInfo = Translations.Daily;
            if (recurrenceIntervalDetail) {
                recursInfo = Translations.EveryWeekday;
            } else {
                recursInfo += ` ${Translations.Every} ${recurrenceInterval.toString()} ${Translations.DayInterval}`;
            }
        } else if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Weekly)) {
            recursInfo = Translations.Weekly;
            recursInfo += ` ${Translations.Every} ${recurrenceInterval.toString()} ${Translations.WeekIntervalOn} `;
            const weekdays = [Translations.Monday, Translations.Tuesday, Translations.Wednesday, Translations.Thursday, Translations.Friday, Translations.Saturday, Translations.Sunday];
            const setWeekdays: string[] = [];
            weekdays.forEach((weekdayName, weekday) => {
                const num = Math.pow(10, weekday);
                const isSet = Math.floor(recurrenceIntervalDetail / num) % 2 > 0.5;
                if (isSet) {
                    setWeekdays.push(weekdayName);
                }
            });
            recursInfo += setWeekdays.join(", ");
            recursInfo = recursInfo.replace(/,(?=[^,]*$)/, " " + Translations.And);
        } else if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Monthly)) {
            recursInfo = Translations.Monthly;
            recursInfo += ` ${Translations.Every} ${recurrenceInterval.toString()} ${Translations.MonthIntervalOn} ${recurrenceIntervalDetail.toString()} ${Translations.Day}`;
        } else if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Yearly)) {
            recursInfo = `${Translations.Yearly} ${Translations.Every} ${recurrenceInterval} ${Translations.Year} `;
            const month = Math.floor(recurrenceIntervalDetail / 100);
            const day = recurrenceIntervalDetail % 100;
            const monthNames = [
                Translations.January,
                Translations.February,
                Translations.March,
                Translations.April,
                Translations.May,
                Translations.June,
                Translations.July,
                Translations.August,
                Translations.September,
                Translations.October,
                Translations.November,
                Translations.December
            ];
            recursInfo += monthNames[month - 1] + " " + day + " " + Translations.Day;
        }
        return recursInfo.toLowerCase();
    }
    // #endregion workOrder

    // #region LocationPoint
    static generateDirectionsMapLink(directionMapLinkTemplate: string, mapLinkTemplate: string, locationPoints: ILocationPoint[]) {
        if (!directionMapLinkTemplate || !locationPoints) return "";
        const validLocationPoints = locationPoints.filter(i => !!i.longitude && !!i.latitude);
        if (validLocationPoints.length < 1) return "";
        if (validLocationPoints.length < 2) {
            return Base.generateMapLinkByCoordinates(mapLinkTemplate, validLocationPoints[0].latitude, validLocationPoints[0].longitude);
        }
        const origin = Base.getGoogleMapCoordinateStr(validLocationPoints[0].latitude, validLocationPoints[0].longitude);
        const destination = Base.getGoogleMapCoordinateStr(validLocationPoints[validLocationPoints.length - 1].latitude, validLocationPoints[validLocationPoints.length - 1].longitude);
        const waypoints: string[] = [];
        if (validLocationPoints.length > 2) {
            for (let i = 1; i < validLocationPoints.length - 1; i++) {
                waypoints.push(Base.getGoogleMapCoordinateStr(validLocationPoints[i].latitude, validLocationPoints[i].longitude));
            }
        }
        return String.format(directionMapLinkTemplate, origin, destination, waypoints.join("|"));
    }
    // #endregion LocationPoint

    // #region Dialogs
    static showSuccessMessage(message: string) {
        store.customStore.dispatch(storeActions.showSuccessMessage(message));
    }

    static showErrorMessage(message: string) {
        store.customStore.dispatch(storeActions.showErrorMessage(message));
    }

    static showApiErrorMessage(e) {
        AppUtils.showErrorMessage(baseService.getErrorMessageFromError(e));
    }

    static isIApiResponse(data: any): data is IApiResponse {
        if (Base.isString(data)) return false;
        return data && "message" in data;
    }

    static async callService<T>(serviceCall: () => Promise<T>, showSuccessMessage: boolean = true, disableLoadIndicator: boolean = false, onFinally: () => void = null): Promise<T> {
        if (!disableLoadIndicator) {
            store.customStore.dispatch(storeActions.fetchStart());
        }
        try {
            const result = await serviceCall();
            if (showSuccessMessage) {
                if (AppUtils.isIApiResponse(result) && result.message) {
                    AppUtils.showSuccessMessage(result.message);
                } else if (Base.isString(result) && result) {
                    AppUtils.showSuccessMessage(result);
                }
            }
            return result;
        } catch (e) {
            AppUtils.showApiErrorMessage(e);
            return Base.getPromiseResult<T>(null);
        } finally {
            if (!disableLoadIndicator) {
                store.customStore.dispatch(storeActions.fetchEnd());
            }
            if (onFinally) {
                onFinally();
            }
        }
    }

    static showConfirmationDialog(message: string, showCancelButton: boolean = false): Promise<ConfirmationDialogResult> {
        return new Promise<ConfirmationDialogResult>((resolve) => {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, message,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    resolve(ConfirmationDialogResult.Yes);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    resolve(ConfirmationDialogResult.No);
                },
                showCancelButton
                    ? () => {
                        store.customStore.dispatch(storeActions.clearConfirmation());
                        resolve(ConfirmationDialogResult.Cancel);
                    }
                    : null
            ));
        });
    }

    static async validate(checkErrors: () => Promise<boolean>, getWarnings: () => Promise<string> = null, confirmationMessage: string = null): Promise<boolean> {
        if (!await checkErrors()) return false;
        const warnings = getWarnings ? await getWarnings() : "";
        if (!warnings) {
            return true;
        }
        return await AppUtils.showConfirmationDialog(warnings + Base.lf + (confirmationMessage || Translations.DoYouReallyWantToSaveData)) === ConfirmationDialogResult.Yes;
    }

    static async cancel(newStateHash: string, orgStateHash: string): Promise<ConfirmationDialogResult> {
        if (newStateHash !== orgStateHash) {
            return await AppUtils.showConfirmationDialog(Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges, true);
        } else {
            return Base.getPromiseResult<ConfirmationDialogResult>(ConfirmationDialogResult.No);
        }
    }
    // #endregion Dialogs

    static translate = (str: string): string => {
        /*eslint-disable   @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
        return i18n.t(str);
    };
}
